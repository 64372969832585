import React from 'react'
import Creath from '../../assets/c_logo.png'
import Twitter from '../../assets/twitterSVG.svg'
import Instagram from '../../assets/instagramSVG.svg'
import Medium from '../../assets/mediumSVG.svg'
import Discord from '../../assets/discordSVG.svg'
import LinkedIn from '../../assets/linkedinSVG.svg'
import Youtube from '../../assets/youtube.png'
import './footer.css'
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const Footer = ()=>{

    const navigate = useNavigate();

    const changePage = (page)=>{
        navigate(page)
    }


    const openSocial = (link)=>{
        window.open(link,"_blank")
    }

    const openPage = ()=>{
        window.open('https://creath.medium.com/',"_blank")
    }

    return(
        <div className='footer_main'>
            <h1>Creath Art Marketplace</h1>
            <p className="header_p">Where Digital Meets Physical</p>
            <div className='footer_buttons'>
                <button onClick={()=>{changePage("/creatorForm")}}>Become a Creator</button>
                <button>Hire a Talent</button>
                <button onClick={()=>{changePage("/blogs")}}>Blog</button>
                <button onClick={()=>{changePage("/termsandcondition")}}>Terms and Condition</button>
                <button onClick={()=>{changePage("/shipping")}}>Shipping & Details</button>
                <button className='odd' onClick={()=>{changePage("/resources")}}>Resources</button>
            </div>
            <div className='footer_logos'>
                <LazyLoadImage onClick={()=>{openSocial("https://Creath.io")}} src={Creath} alt="Creath Logo: Where digital meets physical" className='footer_creath'/>
                <div className='social_logo'>
                    <LazyLoadImage onClick={()=>{openSocial("https://www.instagram.com/Creathlabs")}} src={Instagram} alt="Instagram Logo: link to Chreat instagram page"/>
                    <LazyLoadImage onClick={()=>{openSocial("https://twitter.com/CreathLabs")}} src={Twitter} alt="Twitter Logo: link to Chreat twitter page" />
                    <LazyLoadImage onClick={()=>{openSocial("https://www.linkedin.com/company/creath-io/")}} src={LinkedIn} alt="LinkedIn: link to Chreat LinkedIn profile"/>
                    <LazyLoadImage onClick={()=>{openSocial("https://discord.gg/Sqgb9hsZ5B")}} src={Discord} alt="Discord: link to Chreat discord channel"/>
                    <LazyLoadImage onClick={()=>{openSocial("https://medium.com/@creath")}} src={Medium} alt="Medium: link to Chreat medium blog" />
                    <LazyLoadImage onClick={()=>{openSocial("https://www.youtube.com/@creath")}} src={Youtube} alt="Youtube: link to Chreat youtube channel." />
                </div>
            </div>
            <p> © 2024 Creath. All Rights Reserved</p>
        </div>
    )
}

export default Footer