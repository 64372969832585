import React, { useEffect, useState } from 'react';
import './header.css'
import Select from 'react-select';
import Logo from '../../assets/creathscg.svg'
import Web3 from 'web3';
import { useNavigate } from 'react-router-dom';
import Dot from '../../assets/dot.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Header = (props)=>{
  const [account, setAccount] = useState(null)
  const web3 = new Web3(window.ethereum);
  const current = sessionStorage.getItem("currentPage") ? sessionStorage.getItem("currentPage") : "home";
  const options1 = [
    { value: 'profile', label: 'Profile' },
    { value: 'logout', label: 'Sign Out' }
  ]

  const options2 = [
    {value: 'mn', label: 'ETH Mainnet'},
    {value: 'op', label: 'Optimism'}
  ]

  const navigate = useNavigate();

  const dropdownChange = (option)=>{
    if(option.value === 'logout'){
      if(localStorage.getItem('connector')==="injected"){
        web3.setProvider(null);
        localStorage.removeItem('account');
        localStorage.removeItem("currentUser")
        localStorage.setItem('connector','')
        sessionStorage.removeItem("favorites");
        props.setCurrentAccount(null)
        localStorage.setItem('isLoggedIn', 'false')
        props.setIsLoggedIn(false)
        setAccount(null)
        changePage('/','home')
        props.setDisconnectState(props.disconnectState + 1)
      }
      else{
        localStorage.removeItem('account');
        props.setCurrentAccount(null)
        localStorage.setItem('isLoggedIn', 'false')
        localStorage.removeItem("currentUser")
        localStorage.setItem('connector','')
        sessionStorage.removeItem("favorites");
        props.setIsLoggedIn(false)
        props.disconnectWallet()
        setAccount(null)
        changePage('/','home')
        props.setDisconnectState(props.disconnectState + 1)
      }
    }
    else{
      if(localStorage.getItem('role') === "artist"){
        navigate(`/artistProfile?id=${JSON.parse(localStorage.getItem('currentUser')).id}`)
      }
      else{
        navigate("/profile")
      }
    }
}

// const dropdownChange2 = (option)=>{
//   if(localStorage.getItem('connector')==="injected"){
//     if(option.value === "mn"){
//       props.switchMetaMaskNetwork("1")
//     }
//     else{
//       props.addNetwork()
//     }
//   }
//   else{
//     if(option.value === "mn"){
//       props.switchWalletConnectNetwork(0)
//     }
//     else{
//       props.switchWalletConnectNetwork(1)
//     }
//   }
// }
  
  useEffect(()=>{
    const accnt = localStorage.getItem('account');
    let page = sessionStorage.getItem("currentPage");
    if(accnt){
      setAccount(accnt)
      props.setCurrentAccount(accnt)
    }
    if(page){
      props.setCurrentPage(page);
    }

  },[props.connectionState, props.disconnectState])


  const changePage = (page,pageName)=>{
    navigate(page)
    props.setCurrentPage(pageName)
    sessionStorage.setItem("currentPage",`${pageName}`)
  }

  return(
      <div className={sessionStorage.getItem("verification") ? "Header dont" : "Header"}>
          <div onClick={()=>{changePage('/','home')}} className='image' role="button">
            <LazyLoadImage src={Logo} alt="Creath Logo: Creativity x Innovation"/>
          </div>
          <div className='menu'>
            <div>
              <p className={current === 'home' ? 'selectedText' : ''} onClick={()=>{changePage('/','home')}}>Home</p>
              <LazyLoadImage className={current === 'home' ? 'selected dot' : 'dot'} src={Dot} alt="This is a selected menu indicator"/>
            </div>
            <div>
              <p className={current === 'marketplace' ? 'selectedText' : ''}  onClick={()=>{changePage('/marketplace','marketplace')}}>Marketplace</p>
              <LazyLoadImage className={current === 'marketplace' ? 'selected dot' : 'dot'} src={Dot} alt="This is a selected menu indicator"/>
            </div>
            <div>
              <p className={current === 'artists' ? 'selectedText' : ''}  onClick={()=>{changePage('/artists','artists')}}>Artists</p>
              <LazyLoadImage className={current === 'artists' ? 'selected dot' : 'dot'} src={Dot} alt="This is a selected menu indicator"/>
            </div>
            <div>
              <p className={current === 'exhibition' ? 'selectedText' : ''}  onClick={()=>{changePage('/exhibition','exhibition')}}>Exhibitions</p>
              <LazyLoadImage className={current === 'exhibition' ? 'selected dot' : 'dot'} src={Dot} alt="This is a selected menu indicator"/>
            </div>
            <div>
              <p className={current === 'blogs' ? 'selectedText' : ''}  onClick={()=>{changePage('/blogs','blogs')}}>Blogs</p>
              <LazyLoadImage className={current === 'blogs' ? 'selected dot' : 'dot'} src={Dot} alt="This is a selected menu indicator"/>
            </div>
          </div>
          <div className='buttons'>
            {
              localStorage.getItem('account') ?
              <div className='headerOption'>
                {/* <Select 
                  options={options2} 
                  isSearchable={false}
                  onChange={dropdownChange2} 
                  className="headersearchSelect" 
                  placeholder= "Supported Networks"
                  value= "Supported Networks"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: 'lightgrey',
                      primary: 'black',
                    },
                  })}
                /> */}

                <Select 
                  options={options1} 
                  isSearchable={false}
                  onChange={dropdownChange} 
                  className="headersearchSelect headersearchSelect2" 
                  placeholder={`${localStorage.getItem('account').substring(0,5)}....${localStorage.getItem('account').substring(localStorage.getItem('account').length-4,localStorage.getItem('account').length)}`}
                  value={`${localStorage.getItem('account').substring(0,5)}....${localStorage.getItem('account').substring(localStorage.getItem('account').length-4,localStorage.getItem('account').length)}`}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: 'lightgrey',
                      primary: 'black',
                    },
                  })}
                />
            </div>
            :
            <button className='Login' disabled={localStorage.getItem("account") ? true : false} onClick={()=>{changePage('/login','home')}}>{localStorage.getItem('account') ? `${localStorage.getItem('account').substring(0,5)}....${localStorage.getItem('account').substring(account.length-4,account.length)}` : "Login"}</button>
            }
            
            
          </div>
      </div>
  )
}

export default Header