import React, {useState, useEffect} from "react";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Footer from "../../Components/Footer/footer";
import Blog from "../../Components/BlogCard/blog";
import './blogs.css';



const BlogsPage = (props)=>{
    const staticBlogs = props.blog;
    const [blogs, setBlog] = useState(props.blog)
    const [pageNumber, setPageNumber] = useState(0)
    const [currentPagin, setCurrentPagin] = useState(1)
    const [blog, setBlogs] = useState([])

    useEffect(()=>{
        let startIndex = (currentPagin * 15) - 15;
        let endIndex = (currentPagin * 15) - 1
        setPageNumber(Math.ceil(blogs.length/15));
        
        setBlogs(
            blogs.map((user,i)=>{
                if((i >= startIndex) && (i <= endIndex)){
                    return(
                        <Blog key={blogs[i].id} id = {blogs[i].id} cover = {blogs[i].cover_image} content = {blogs[i].content} title = {blogs[i].title} description = {blogs[i].description} post = {blogs[i]}  />
                    )
                }
            })
        )

    },[currentPagin,blogs])

    const paginChange = (event, value)=>{
        setCurrentPagin(value);
    }


    const searchChange = (event)=>{
        setBlog(staticBlogs.filter((arr)=>{
            return arr.title.toLowerCase().includes(event.target.value.toLowerCase())
        }))
    }



    return(
        <div className="artistsMain">
            <div className="artistsHero">
                <h1>Blogposts</h1>
                <input type="text" placeholder="Search for artist" onChange={searchChange}/>
            </div>
            <div className="artistsContainer blogposts">
                <div className='arts'>
                    {blog}
                </div>
                <div className='pagins'>
                    <Stack spacing={2}>
                        <Pagination count={pageNumber} variant="outlined" shape="rounded" onChange={paginChange} />
                    </Stack>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default BlogsPage