import React, { useEffect, useState } from 'react';
import './collectionProfile.css';
import ArtCard from "../../Components/ArtCard/artcard";
import Empty from '../../assets/empty.png'
import { useSearchParams } from "react-router-dom";
import Footer from '../../Components/Footer/footer';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { isMobile, isIPad13, isTablet } from 'react-device-detect';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';




const CollectionProfile = (props)=>{
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [personalArts, setPersonalArts] = useState([])
    const [collection, setCollection] = useState({})

    useEffect(()=>{
        const Arts = JSON.parse(sessionStorage.getItem('arts'));
        const arts = []
        fetch(`https://creath.tech/api/collections/${id}`)
        .then(response => response.json())
        .then((data)=>{
           setCollection(data.data)
           for(let i= 0; i <Arts.length; i++){
                if(Arts[i].collection_id === `${data.data.id}`){
                    arts.push(Arts[i])
                } 
           }
           setPersonalArts(arts)
        })
        .catch((e)=>{
            setPersonalArts(arts)
        })
    },[])
    
    return(
        <div className='collectionProfileMain'>
            <div className='collectionDetails'>
                <div className='coverImage'>
                    <LazyLoadImage src={`${collection.cover_image}`} alt={collection.name}/>
                </div>
                <div className='collectionTexts'>
                    <p className='collectionName'>{collection.name}</p>
                    <p className='collectionDescription'>{collection.description}</p>
                    <p className='collectionArtworks'>Number of Artworks: {personalArts.length}</p>
                </div>
            </div>
            <div className='collectionWorks'>
                <h1>Collection Arts</h1>
                {
                    personalArts.length === 0 ?
                    <LazyLoadImage className="image_404" src={Empty} alt="There are no artworks"/>
                    :
                    <div className="worksSlides">
                        <CarouselProvider
                            naturalSlideWidth={100}
                            naturalSlideHeight={125}
                            totalSlides={personalArts.length}
                            visibleSlides={isMobile && isIPad13 ? 3 : isMobile && isTablet ? 3 : isMobile ? 1 : 3}
                        >
                            <Slider>
                                {
                                    personalArts.map((el,i)=>{
                                        return(
                                            <Slide key={personalArts[i].id} index={i}>
                                                <ArtCard artistName={personalArts[i].artist_name} Art={personalArts[i]} physical = {personalArts[i].has_physical_copy} key={personalArts[i].id} id={personalArts[i].id} img={`${personalArts[i].art_image}`} title={personalArts[i].name} description={personalArts[i].description}  artist={personalArts[i].artist_id} price={personalArts[i].price} style={{color: "red"}} setProduct = {props.setProduct} setArtist = {props.setArtist}/>
                                            </Slide>
                                        )
                                    })
                                }
                            </Slider>
                            <ButtonBack>{'<'}</ButtonBack>
                            <ButtonNext>{'>'}</ButtonNext>
                        </CarouselProvider>
                    </div>
                }
            </div>
            <Footer/>
        </div>
    )
}

export default CollectionProfile