import React, {useEffect} from "react";



const LiveChat = ()=>{
    useEffect(() => {
        window.chatwootSettings = {
        hideMessageBubble: false,
        position: "right", 
        locale: "en", 
        type: "standard" 
        };
        
        (function(d,t) {
            let BASE_URL = "https://app.chatwoot.com";
            let g = d.createElement(t), s = d.getElementsByTagName(t)[0];
            g.src = BASE_URL + "/packs/js/sdk.js";
            g.defer = true;
            g.async = true;
            s.parentNode.insertBefore(g, s);
        
            g.onload = function() {
                window.chatwootSDK.run({
                websiteToken: 'gLAdcuge5tNS8GKioj9k2cqA',
                baseUrl: BASE_URL
            })
        }
        }(document, "script"));
    }, []);

    return null
}


export default LiveChat