import React, { useEffect, useState} from "react";
import { ethers } from "ethers";
import ABI from '../../assets/ContractABI.json'
import CreathABI from '../../assets/CreathABI.json'
import MockABI from '../../assets/MockABI.json'
import Zoom from 'react-medium-image-zoom';
import Connect from '../../assets/connect.svg';
import Modal from 'react-modal';
import './checkout.css';
import {useNavigate } from "react-router-dom";
// import { useEthersSigner } from "../../ethersUtils";
// import { useNetwork, useSwitchNetwork } from 'wagmi'
import Loader from '../../assets/loader.gif';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams } from 'react-router-dom';
import Web3 from 'web3';



const Checkout = (props)=>{
    const [verified, setVerified] = useState(false);
    const [Arts, setArts] = useState({});
    const [Artist, setArtist] = useState({})
    const [contract, setContract] = useState([]);
    const [mockContract, setMock] = useState([]);
    const [loading, setLoading] = useState(false)
    const [errors, setError] = useState(false)
    const [transactionLink, setTransactionLink] = useState("")
    const [signer, setSigner] = useState([]);
    const [paymentOption, setPaymentOption] = useState("Wallet");
    const [emailSent, setEmailSent] = useState(false)
    const [approved, setApproved] = useState(false)
    const [ensure, setEnsure] = useState(0)
    const[done, setDone] = useState(false)
    const [artCollection, setCollection] = useState([])
    const [transferContract, setTransferContract] = useState({})
    const [error, setErrors] = useState(false)
    const contractAddress = '0x013b6f5a3fF3A3259d832b89C6C0adaabe59f8C6'; //it is the same contract for buying and listing items, it is also used in artistProfile and profile js files
    const creathAddress = "0x4DF3Fbf82df684A16E12e0ff3683E6888e51B994";
    const mockContractAddress = "0x7f5c764cbc14f9669b88837ca1490cca17c31607" //for approval
    const User = JSON.parse(localStorage.getItem("currentUser"));
    const web3 = new Web3(window.ethereum);
    const { id } = useParams();

    const PROVIDER = "https://optimism.drpc.org"
    const provider = new ethers.providers.JsonRpcProvider(PROVIDER);
    const collectionPrivateKey = "cf4eede6dbc634879e6feb13601d36cf55b2a7cfc3593e646e26ef9c5dd27921";
    const AdminWallet = new ethers.Wallet(collectionPrivateKey, provider);

    const navigate = useNavigate()
    // const providers = useEthersSigner();
    Modal.setAppElement('#root');
    // const { chain } = useNetwork()
    // const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork({
    //     onError(error) {

    //     }
    // })

    const config = {
        public_key: 'FLWPUBK-0b212880f051f3e79e3b654d97a61fb7-X',
        tx_ref: Date.now(),
        amount: `${Arts.price}`,
        currency: 'USD',
        payment_options: 'card, banktransfer, ussd, account, credit',
        customer: {
        email: `${User ? User.email : ''}`,
        phone_number: `${User ? User.phone : ''}`,
        name: `${User ? User.full_name : ''}`,
        },
        customizations: {
        title: `Paying for artwork ${Arts.name}`,
        description: 'Payment for an artwork on Creath Marketplace',
        logo: 'https://media.publit.io/file/creat-logo.webp',
        },
    };

    const handleFlutterPayment = useFlutterwave(config);
  

    useEffect(()=>{
        if(localStorage.getItem('connector') === 'injected'){
            switchNetwork()
        }
    })


    const switchNetwork = async ()=>{
        const currentChain  = await web3.eth.getChainId()
        if(currentChain !== 10){
            props.addNetwork()
        }
    }



    useEffect(()=>{
        // try{
        //     switchNetwork(chains[1].id)
        // }
        // catch(e){
        //     console.log(error)
        // }
        setTimeout(()=>{
            if(localStorage.getItem('connector') === 'injected'){
                const ContractInstance = new ethers.Contract(contractAddress, ABI, props.wallet)
                // const provider = new ethers.providers.Web3Provider(props.library.provider)
                const MockContract = new ethers.Contract(mockContractAddress, MockABI, props.wallet)
                setMock(MockContract)
                setContract(ContractInstance)
                setSigner(props.wallet)
            }
            // else{
            //     if(providers){
            //         const ContractInstance = new ethers.Contract(contractAddress, ABI, providers)
            //         // const provider = new ethers.providers.Web3Provider(props.library.provider);
            //         const MockContract = new ethers.Contract(mockContractAddress, MockABI, providers)
            //         setMock(MockContract)
            //         setContract(ContractInstance)
            //         setSigner(props.wallet)
            //     }
            //     else{
            //         setEnsure(ensure + 1)
            //     }
            // }   
        },1000)
    },[props.library, ensure, id])

    useEffect(()=>{
 
        const arts = props.Artss;
        const art = arts.filter((el)=>{
            return `${el.id}` === `${id}`
        })
        if(art[0]){
            setArts(art[0]);
            const artists = props.Artists;
            const artist =  artists.filter((el)=>{
                return `${el.id}` === `${art[0].artist_id}`
            })
            setArtist(artist[0])
            const collections = props.Collections
            if(art[0].collection_id !== null){
                for(let i = 0; i < collections.length; i++){
                    if(`${collections[i].id}` === art[0].collection_id){
                        setCollection(collections[i])
                        const contract= new ethers.Contract(contractAddress, ABI, AdminWallet);
                        setTransferContract(contract)
                    }
                }
            }
        }
        else{
            setErrors(true)
        }
        
    },[id])

    const changePage = ()=>{
        navigate('/login')
    }

    useEffect(()=>{
        const logged = localStorage.getItem('isLoggedIn');

        if(props.isLoggedIn === true || logged === "true"){
            setVerified(true)
        }
    },[])


    const changePaymentOption = (e)=>{
        setPaymentOption(e.target.value)
    }


    const handleBuy = async ()=>{
        setLoading(true)
        let price = ethers.utils.parseUnits(Arts.price, 6)
        let id = ethers.BigNumber.from(Arts.nft_id)
        if(paymentOption === "Wallet"){
            if(artCollection.length === 0){
                try{
                    let allowance = await mockContract.allowance(localStorage.getItem('account'), contractAddress);
                    let Txn;
                    if(parseInt(Arts.price) > parseInt(allowance._hex, 16)){
                        Txn = await mockContract.approve(contractAddress, `${parseInt(price._hex)}`)
                        let rec = await Txn.wait()
                        if(!rec){
                            setError(true)
                            setLoading(false)
                        }
                        else{
                            setApproved(true)
                        }
                    }
                    else{
                        setApproved(true)
                    }
                    Txn = await contract.buyItem(creathAddress, User.wallet, id, false)
                    let receipt = await Txn.wait()
                    if(receipt.transactionHash){
                        setLoading(false)
                        const data2 =  new  FormData()
                        data2.append("collectorName", User.full_name)
                        data2.append("email", User.email)
    
                        const data3 = new FormData()
                        data3.append("artistName", Artist.full_name)
                        data3.append("artworkTitle", Arts.name)
                        data3.append("collectorName", User.full_name)
                        data3.append("salePrice", `${Arts.price}`)
                        data3.append("email", Artist.email)
    
                        setTransactionLink(`https://sepolia.etherscan.io/tx/${receipt.transactionHash}`)
                        setDone(true)
    
                        fetch("https://creath.tech/api/send-artist-email",{
                            method: "POST",
                            body: data3
                        })
                        .then(response => {return response.json()})
                        .catch((e)=>{})
    
                        fetch("https://creath.tech/api/send-collector-email",{
                            method: "POST",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: data2
    
                        }).then((response)=>{
                            return response.json()
                        })
                        .then((data)=>{
                            if(data.message){
                                setEmailSent(true)
                                setTimeout(()=>{
                                    navigate('/marketplace')
                                },3000)
                            }
                        })
                        .catch((e)=>{
                            setEmailSent(true)
                            setTimeout(()=>{
                                navigate('/marketplace')
                            },3000)
                        })
                    }
                    else{
                        setError(true)
                        setLoading(false)
                    }
                }
                catch(e){
                    setError(true)
                    setLoading(false)
                }
            }
            else{
                try{
                    let allowance = await mockContract.allowance(localStorage.getItem('account'), contractAddress);
                    let Txn;
                    if(parseInt(Arts.price) > parseInt(allowance._hex, 16)){
                        Txn = await mockContract.approve(contractAddress, `${parseInt(price._hex)}`)
                        let rec = await Txn.wait()
                        if(!rec){
                            setError(true)
                            setLoading(false)
                        }
                        else{
                            setApproved(true)
                        }
                    }
                    else{
                        setApproved(true)
                    }
                    Txn = await contract.buyItem(artCollection.nft_address,User.wallet, id, false)
                    let receipt = await Txn.wait()
                    
                    if(receipt.transactionHash){
                        setLoading(false)
                        setTransactionLink(`https://sepolia.etherscan.io/tx/${receipt.transactionHash}`)
                        setDone(true)
    
                        const data2 =  new  FormData()
                        data2.append("collectorName", User.full_name)
                        data2.append("email", User.email)
    
                        const data3 = new FormData()
                        data3.append("artistName", Artist.full_name)
                        data3.append("artworkTitle", Arts.name)
                        data3.append("collectorName", User.full_name)
                        data3.append("salePrice", `${Arts.price}`)
                        data3.append("email", Artist.email)
    
                        fetch("https://creath.tech/api/send-artist-email",{
                            method: "POST",
                            body: data3
                        })
                        .then(response => {return response.json()})
                        .catch((e)=>{})
    
    
                        fetch("https://creath.tech/api/send-collector-email",{
                            method: "POST",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: data2
                        }).then((response)=>{
                            return response.json()
                        })
                        .then((data)=>{
                            if(data.message){
                                setEmailSent(true)
                                setTimeout(()=>{
                                    navigate('/marketplace')
                                },3000)
                            }
                        })
                        .catch((e)=>{
                            setEmailSent(true)
                            setTimeout(()=>{
                                navigate('/marketplace')
                            },3000)
                        })
                        
                    }
                    else{
                        setError(true)
                        setLoading(false)
                    }
                }
                catch(e){
                    setError(true)
                    setLoading(false)
                    console.log(e)
                }
            }
        }
        else{
            handleFlutterPayment({
                callback: async (response) => {
                    
                    if(response.status === "successful"){
                        console.log(transferContract)
                        const result = await transferContract.buyItem(artCollection.nft_address, User.wallet, id, true);
                        console.log(result)
                        setApproved(true)
                        setDone(true)
                        const data2 =  new  FormData()
                        data2.append("collectorName", User.full_name)
                        data2.append("email", User.email)

                        const data3 = new FormData()
                        data3.append("artistName", Artist.full_name)
                        data3.append("artworkTitle", Arts.name)
                        data3.append("collectorName", User.full_name)
                        data3.append("salePrice", `${Arts.price}`)
                        data3.append("email", Artist.email)
    
                        fetch("https://creath.tech/api/send-artist-email",{
                            method: "POST",
                            body: data3
                        })
                        .then(response => {return response.json()})
                        .catch((e)=>{})
    
    
                        fetch("https://creath.tech/api/send-collector-email",{
                            method: "POST",
                            body: data2
                        }).then((response)=>{
                            return response.json()
                        })
                        .then((data)=>{
                            if(data.message){
                                setEmailSent(true)
                                setTimeout(()=>{
                                    navigate('/marketplace')
                                },3000)
                            }
                        })
                        .catch((e)=>{
                            setEmailSent(true)
                            setTimeout(()=>{
                                navigate('/marketplace')
                            },3000)
                        })
                    }    
                    closePaymentModal() // this will close the modal programmatically
                },
                onClose: () => {
                    setError(true)
                    setLoading(false)
                },
            });
        }
    }


    const media = String(Arts.art_image);
    return(
        <div className="mainCheckout">
            {
                error ?
                <div className='four'>
                    <h1>404</h1>
                    <p>Product not found</p>
                    <button onClick={()=>{navigate('/')}}>Go to home</button>
                </div>
                :
                <>
                    <div className="subMainCheckout">
                        {
                            !verified ?
                            <div className="goLogin">
                                <LazyLoadImage src={Connect} alt="Connect your wallet"/>
                                <p>!Oops Connect your<br /> wallet to make purchase.</p>
                                <button onClick={changePage}>Connect Wallet</button>
                            </div>

                            :

                            <div className="checkout">
                                <div className="workInfo">
                                    {
                                        media.includes("mp4") ?
                                        <video playsInline className="checkoutMedia checkoutVideo" autoPlay={true} muted={true} loop >
                                            <source src={`${(Arts.art_image).slice(0,29)}q_20/${(Arts.art_image).slice(29)}`} type="video/mp4" />
                                        </video>
                                        :
                                        <Zoom><LazyLoadImage className="checkoutMedia" src={`${(Arts.art_image.substr(0, Arts.art_image.lastIndexOf(".")) + ".webp").slice(0,29)}q_20/${(Arts.art_image.substr(0, Arts.art_image.lastIndexOf(".")) + ".webp").slice(29)}`} alt="Artwork"/></Zoom>
                                    }
                                    <div className="artistInfo">
                                        <p>Name: {Arts.name}</p>
                                        <p>Price: {Arts.price}</p>
                                        <p>By: {Artist.username}</p>
                                    </div>
                                </div>
                                <div className="CheckOutSide">
                                    <h2>Checkout</h2>
                                    <p>Price: {Arts.price} USDC</p>
                                    <hr />
                                    <p>Total: {Arts.price} USDC</p>
                                    <div className="paymentOptions">
                                        <p>Select a payment option</p>
                                        <div className="options">
                                            <div>
                                                <input type="radio" name="payment" value="Wallet" checked={paymentOption === "Wallet"} id="wallet" onChange={changePaymentOption}/>
                                                <label htmlFor="wallet">Wallet</label>
                                            </div>
                                            <div>
                                                <input type="radio" name="payment" value="Card" checked={paymentOption === "Card"} id="card" onChange={changePaymentOption}/>
                                                <label htmlFor="card">Pay with flutterwave</label>
                                            </div>
                                        </div>
                                    </div>
                                    <button className={loading ? "started" : ''} onClick={handleBuy}>Buy Now</button>
                                    <div className={loading ? "paymentProcess showProcess" : "paymentProcess"}>
                                        <p>Processing payment</p>
                                        <div>
                                            <LazyLoadImage className={approved ? "unshow" : ""} src={Loader} alt="approving payment"/>
                                            <p>Approving amount (Open your wallet app to approve the amount)</p>
                                        </div>
                                        <div>
                                            <LazyLoadImage className={done ? "unshow" : ""} src={Loader} alt="approving payment"/>
                                            <p>Buying Artwork (Open your wallet app to complete the transaction)</p>
                                        </div>
                                        <div>
                                            <LazyLoadImage  className={emailSent ? "unshow" : ""} src={Loader} alt="approving payment"/>
                                            <p>Sending confirmation email</p>
                                        </div>
                                        <div>
                                            <LazyLoadImage className={emailSent ? "unshow" : ""} src={Loader} alt="approving payment"/>
                                            <p>{emailSent ? "Redireting..." : "Transaction Complete"}</p>
                                        </div>
                                    </div>    
                                </div>   
                                {/* <div className="checkoutText">
                                    <p>Listing Price</p>
                                    <p>{Arts.price} USDC</p>
                                </div>
                                <div className="checkoutText" >
                                    <p>SubTotal</p>
                                    <p>{Arts.price} USDC</p>
                                </div>
                                <button onClick={handleBuy}  className={loading ? "whiteloading checkoutButton" : "checkoutButton"}>Buy Now</button>
                                {error ? <p className="error">There was an error while processing purchase please try again later</p>: ""}
                                <div className={done ? "trans_done show_trans_done" : "trans_done" }>
                                    <div className="trans_details">
                                        <h2>Purchase Successful</h2>
                                        <p>You have successfully purchased an artwork, a confirmation has been sent to your email at :  {User.email}</p>
                                        <p>Please wait, you will be redirected soon.</p>
                                    </div>
                                </div> */}
                            </div>
                        }
                    </div>
                </>
            }
            
        </div>
    )

}

export default Checkout;