import React from 'react';
import FAQ from '../../Components/FAQ/faq';
import Footer from '../../Components/Footer/footer';
import './faqPage.css'


const FaqPage = ()=>{
    return(
        <div className='faqPage'>
            <div className='faqPageMain'>
                <h1>Frequently Asked Questions</h1>
            </div>
            <div className='freq'>
                <FAQ question = {"What is Creath?"} answer = 
                {"<p>Creath is a next-generation tech company that delivers Web3, FinTech, PropTech, and Blockchain services. Our company comprises skilled individuals in Blockchain Development, Web Development, Marketing, Visual &amp; Digital Art, Financial Trading, Art Curation, Community Management, Brand Design, and Project Management.</p><br><p>Our platform has an integrated marketplace built on the Ethereum Blockchain. This marketplace will serve as a gallery for artists to display their digitized artworks to a diverse global audience.</p>"}/>

                <FAQ question = {"What Can I do on Creath Marketplace?"} answer = 
                {"<p>The Creath marketplace is designed to be a digital gallery for artists, curators, collectors, and art lovers. The platform is easy to navigate and provides the best user experience. The marketplace attracts a global target audience that will participate in primary sales of our curated collections.</p><br><p>As an artist in partnership with us, your works will be seamlessly available to potential collectors worldwide.</p>"}/>

                <FAQ question = {"What is a Curated Art Collection?"} 
                answer = {"<p>A curated art collection is a collection that is carefully selected by a team of professionals for a particular target audience &mdash;usually because of its cultural value or aesthetics. Creath has a team of curators responsible for determining the collections to be displayed on the marketplace.</p><br><p>The curated collections will include generative art, hand-drawn digital art, and digitized physical artworks.</p>"}/>

                <FAQ question = {"Does Creath Digitize Artworks?"} answer = {"<p>We digitise contemporary artworks into high-resolution Non Fungible Tokens (NFT) on the Blockchain. We also convert digital art to framed physical copies. We provide our primary collectors the original physical art of the NFTs purchased on our platform signed by the artist.</p>"}/>

                <FAQ question = {"What is The Golden Ticket?"} answer = {"<p>The Golden Ticket is a mint pass to all our holders. It will serve as a placeholder in the whitelisting of future projects. There is a limited supply of 1,000 Golden Tickets.</p>"}/>

                <FAQ question = {"What Other Services Does Creath Offer?"} answer = {"<p>Asides from digital art curation, art sales, and exhibitions, Creath offers other Web3 and Blockchain services. <strong>These include:</strong></p><ul><li><strong>Blockchain &amp; Web Development</strong></li><li><strong>Brand Awareness &amp; Marketing</strong></li><li><strong>Market Research</strong></li><li><strong>Art Style</strong></li><li><strong>Brand Survey</strong></li><li><strong>Community Development &amp; Discord Management</strong></li><li><strong>On-Chain Voting Implementation</strong></li><li><strong>Roadmaps</strong></li><li><strong>NFT Release &amp; Token Sale</strong></li></ul>"}/>

                <FAQ question = {"What is Two Brothers and One Lumbo?"} answer = {"<p>Two Brothers and One Lumbo is a QR Code group Exhibition supported by Creath. The exhibition, taking place from August 5 to August 7, is a way of bringing digital and physical artists together within the Web3 space.</p><br><p>Registration for the event is still ongoing, and anyone interested is welcome to register.</p>"}/>

                <FAQ question = {"Which Wallets are Compatible with The Creath Marketplace?"} answer = {"<p>The Creath marketplace is built on the ERC-20 chain and is compatible with ERC-20 stable tokens like USDC. Our developers have performed some minting exercises, alongside unit testing, on the marketplace to test the compatibility and functionalities of the platform.</p><br><p>Anyone using a hardware wallet can still make transactions on Creath through Wallet-Connect and MetaMask.</p>"}/>

                <FAQ question = {"How Much are The Platform Fees?"} answer = {"<p>The Creath platform charges 20% for any art collection listed on the platform. Once any token is bought, 20% of the token&rsquo;s price is sent to the Creath treasury address while the 80% balance instantly goes to the artist.</p>"}/>

                <FAQ question = {"How Do Primary Sales Happen on Creath?"} answer = {"<p>When artists submit their collections to us, we help them convert the art to NFT. The NFT generated through minting is held in the Creath treasury. Once the collection is ready for primary sales, it will be listed at a fixed price (determined by the artist) on the Creath marketplace. Interested external users can proceed to buy the listed NFT and gain ownership of the purchased token. Once ownership is transferred to the buyer, the NFT can be viewed on any secondary marketplace (like OpenSea) or NFT wallet.</p><br><p>Creath marketplace also supports secondary sales. Anyone who wishes to resell their collected tokens to other collectors can do so on our platform</p>"}/>

                <FAQ question = {"Where Can I Get More Information About Creath?"} answer = {"<p>If you need more information about Creath, you can connect with our community on Discord. Our Discord channel has daily conversations that you can join for details about current and expected drops. You can reach out to our moderators for help. The channel also provides information on how to apply as an artist to Creath &mdash;announcements, user feedback, and more are available to you.</p>"}/>
            </div>
            <Footer />
        </div>
    )
}

export default FaqPage;