import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import './blog.css';



const Blog = (props)=>{

    const navigate = useNavigate();

    const openPost = ()=>{
        sessionStorage.setItem("currentBlog", JSON.stringify(props.post))
        navigate(`/blog/${props.id}`)
    }

    return(
        <div onClick={openPost} className='blog_main' role="button">
            <LazyLoadImage src={`${(props.cover.substr(0, props.cover.lastIndexOf(".")) + ".webp").slice(0,29)}q_30/${(props.cover.substr(0, props.cover.lastIndexOf(".")) + ".webp").slice(29)}`} alt="Blog description"/>
            <div className='blog_text'>
                <h2>{props.title}</h2>
            </div>
        </div>
    )
}

export default Blog