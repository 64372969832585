import React from "react";
import Book from '../../assets/svg.svg';
import { useNavigate } from 'react-router-dom';
import './resource.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Resource = (props)=>{

    const navigate = useNavigate();

    const HandleClick = ()=>{
        props.setCurrentResource(props.structure)
        sessionStorage.setItem('resourceStructure',`${props.structure}`)
        props.setsetCurrentResTitle(props.title)
        sessionStorage.setItem('resourceTitle',`${props.title}`)
        navigate(`/resource/${props.title}/${props.id}`)
    }
    return(
        <div className="resourceMain" onClick={HandleClick} role="button">
            <LazyLoadImage src={Book} alt="Creath Resources"/>
            <div className="resourceText">
                <h1>{props.title}</h1>
                <p>{props.description}</p>
            </div>
        </div>
    )
}

export default Resource;