import React, { createRef, useEffect } from 'react';
import YoutubeEmbed from "../../Components/Youtube/youtube";
import FAQ from '../../Components/FAQ/faq';
import Blog from '../../Components/BlogCard/blog';
import Footer from '../../Components/Footer/footer';
import 'react-medium-image-zoom/dist/styles.css'
import './home.css'
import SlideCard from '../../Components/SliderCard/slidercard';
import Modal from 'react-modal';
import Cancel from '../../assets/cancel.png';
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { useNavigate } from 'react-router-dom';
import Broken from '../../assets/broken.png'
import Sport from '../../assets/Sportrex.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Optimisms from '../../assets/Optimism.svg'





const Home = (props)=>{
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [currentSlide, setCurrentSlide] = React.useState(0)
    const [loaded, setLoaded] = React.useState(false)
    const [newsMail, setNewsMail] = React.useState('');
    const [whiteMail, setWhiteMail] = React.useState('');
    const [whiteName, setWhiteName] = React.useState('');
    const [newsletterStat, setnewsletterStat] =  React.useState(0);
    const [whiteStat, setWhiteStat] = React.useState(0);
    const [newsIsLoading, setNewsIsLoading] = React.useState(false);
    const [whiteIsLoading, setWhiteIsLoading] = React.useState(false);
    const [Arts, setArts] = React.useState([])
    const [Artists, setArtists] = React.useState([])
    const posts =  props.blogs || [];

    const sliderOptions = {
        loop: true,
        initial: 0,
        slideChanged(slider) {
          setCurrentSlide(slider.track.details.rel);
        },
        created() {
          setLoaded(true);
        },
      };
    
    const sliderPlugins = [
        (slider) => {
            let timeout;
            let mouseOver = false;
            function clearNextTimeout() {
            clearTimeout(timeout);
            }
            function nextTimeout() {
            clearTimeout(timeout);
            if (mouseOver) return;
            timeout = setTimeout(() => {
                slider.next();
            }, 2000);
            }
            slider.on("created", () => {
            slider.container.addEventListener("mouseover", () => {
                mouseOver = true;
                clearNextTimeout();
            });
            slider.container.addEventListener("mouseout", () => {
                mouseOver = false;
                nextTimeout();
            });
            nextTimeout();
            });
            slider.on("dragStarted", clearNextTimeout);
            slider.on("animationEnded", nextTimeout);
            slider.on("updated", nextTimeout);
        },
    ];

    const [sliderRef, instanceRef] = useKeenSlider(sliderOptions, Arts.length !== 0 ? sliderPlugins : []);


    useEffect(()=>{
        sessionStorage.setItem("currentPage", "home")
        props.setCurrentPage("home")
    },[])

    useEffect(()=>{
        setArts(props.arts)
        setArtists(props.artists)
    },[])

    const updateNewsMail = (event)=>{
        setNewsMail(event.target.value)
    }

    const updateWhiteMail =  (event)=>{
        setWhiteMail(event.target.value);
    }

    const updateWhiteName =  (event)=>{
        setWhiteName(event.target.value);
    }


    const handleNewsClick = ()=>{
        setNewsIsLoading(true)
        fetch("https://creath.tech/api/newsletter",{
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
            body: JSON.stringify({
                email : `${newsMail}`
            })
        }).then((response) =>{
            if(response){
                return response.json()
            }
            else{
                setnewsletterStat(2)
            }
        })
        .then((data)=>{
            if(data){
                setnewsletterStat(1)
                setNewsIsLoading(false)
            }
        })
        .catch((e)=>{
            setnewsletterStat(2);
            setNewsIsLoading(false);
        })
    }

    const handleWhiteListClick = ()=>{
        setWhiteIsLoading(true)
        fetch("https://creath.tech/api/whitelists",{
            method : "POST",
            headers : {
                "Content-type": "application/json, charset=UTF-8"
            },
            body: JSON.stringify({
                name : `${whiteName}`,
                email : `${whiteMail}`
            })
        })
        .then((response)=>{return response.json})
        .then((data)=>{
            if(data){
                setWhiteStat(2);
                setWhiteIsLoading(false)
                setTimeout(()=>{setIsOpen(false)},2000)
            }
        })
        .catch((e)=>{
            setWhiteStat(1)
            setWhiteIsLoading(false)
        })
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const navigate = useNavigate()

    const changePage = (page,pageName)=>{
        navigate(page)
        props.setCurrentPage(pageName)
        localStorage.setItem("currentPage",`${pageName}`)
    }

    Modal.setAppElement('#root');

    const setModalStatus = (n)=>{
        setIsOpen(n);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    // const sliderRef = useRef();


    const openPage = (n)=>{
        window.open(n,"_blank")
    }


    
    return(
        <div className="home_main">
            <div className="main_hero_section">
                <div className="hero_text">
                    <h1>The New Frontier<br />of Art and Collectibles</h1>
                    <p>We are a digital gallery<br />bridging the gap between creators<br />and collectors  </p>
                    <button onClick={()=>{changePage('/marketplace','marketplace')}}>Explore</button>
                </div>
                <h2>Featured Artworks</h2>
                <div ref={Arts.length !==0 ? sliderRef : createRef('')} className="keen-slider">
                    {   
                        Arts.length !== 0 ?
                        Arts.map((el,i)=>{
                            if(!Arts[i].art_image.includes("mp4")){
                                return(
                                    <SlideCard artistName = {Arts[i].artist_name} physical = {Arts[i].has_physical_copy} key={Arts[i].id} id={Arts[i].id} img={`${Arts[i].art_image}`} title={Arts[i].name} description={Arts[i].description} artist={Arts[i].artist_id} price={Arts[i].price} style={{color: "red"}} setProduct = {props.setProduct} setArtist = {props.setArtist} Art={Arts[i]} />
                                )
                
                            }
                            return null
                        })
                        :
                        <></>
                    }
                </div>
                {loaded && instanceRef.current && (
                    <>
                        <Arrow
                        left
                        onClick={(e) =>
                            e.stopPropagation() || instanceRef.current?.prev()
                        }
                        disabled={currentSlide === 0}
                        />

                        <Arrow
                        onClick={(e) =>
                            e.stopPropagation() || instanceRef.current?.next()
                        }
                        disabled={
                            currentSlide === Arts.length - 1
                        }
                        />
                    </>
                )}
            </div>

            <div className="about">
                <h1>About Creath</h1>
                <div className="home_youtube">
                    <YoutubeEmbed embedId="Q7mOzjqQ4Ls" />
                </div>
            </div>
            <div className='topNft'>
                <h1>Top Artworks</h1>
                <div className='top'>
                    {
                        Arts.length !== 0 ?
                        <>
                            <SlideCard artistName = {Arts[0].artist_name} physical = {Arts[0].has_physical_copy} key={0} id={Arts[0].id} img={`${Arts[0].art_image}`} title={Arts[0].name} description={Arts[0].description} artist={Arts[0].artist_id} price={Arts[0].price} style={{color: "red"}} setProduct = {props.setProduct} setArtist = {props.setArtist} Art={Arts[0]} />
                            <SlideCard artistName = {Arts[1].artist_name} physical = {Arts[1].has_physical_copy} key={1} id={Arts[1].id} img={`${Arts[1].art_image}`} title={Arts[1].name} description={Arts[1].description} artist={Arts[1].artist_id} price={Arts[1].price} style={{color: "red"}} setProduct = {props.setProduct} setArtist = {props.setArtist} Art={Arts[1]} />
                            <SlideCard artistName = {Arts[2].artist_name} physical = {Arts[2].has_physical_copy} key={2} id={Arts[2].id} img={`${Arts[2].art_image}`} title={Arts[2].name} description={Arts[2].description} artist={Arts[2].artist_id} price={Arts[2].price} style={{color: "red"}} setProduct = {props.setProduct} setArtist = {props.setArtist} Art={Arts[2]} />
                        </>
                        :
                        <></>
                    }
                </div>
                <button onClick={()=>{navigate('/marketplace')}}>See More</button>
            </div>
            <div className='hotCollections topNft'>
                <h1>Top Artists</h1>
                <div className='hot'>
                    {
                        Artists.map((el,i)=>{
                            if(i <= 4){
                                return (
                                    <div key={Artists[i].id} className="nft" onClick={()=>{changePage(`/artistProfile?id=${Artists[i].id}`,"home")}} role="button">
                                       <LazyLoadImage src={`${(Artists[i].profile_image.substr(0, Artists[i].profile_image.lastIndexOf(".")) + ".webp").slice(0,29) + "q_20/" + (Artists[i].profile_image.substr(0, Artists[i].profile_image.lastIndexOf(".")) + ".webp").slice(29)}`} alt={Artists[i].username}/>
                                        <div className="Text">
                                            <p className="art_name">{Artists[i].username}</p>
                                        </div>
                                    </div>              
                                )
                            }
                            return null
                        })
                    }
                </div>
                <button onClick={()=>{navigate('/artists')}}>See More</button>
            </div>
            <div className="goldenticket">
                <div className="goldenContent">
                    <div className="goldenText">
                        <p>Unlock Art's Digital Frontier with $CGT</p>
                        <h1>The Currency of Creativity</h1>
                        <p>
                            Creath Governance Token ($CGT) is the utility token that facilitates transactions on the Creath Art Marketplace, enables platform governance and empowers its users.
                        </p>
                        <div className='goldenButtons'>
                            <button className='whitepaper' onClick={()=>{openPage("https://whitepaper.creath.io/")}}>View Whitepaper</button>
                        </div>
                    </div>
                    <div className="newsletter">
                        <div className='newsletter_text'>
                            <h1>Join our newsletter<br />
                                to stay updated
                            </h1>
                        </div>
                        <div className='form'>
                            <input type="email" className="email" placeholder='Enter Email' onChange={updateNewsMail} />
                            <button className={newsIsLoading ? "newsloading" : ""} onClick={handleNewsClick}>Submit</button>
                            <p className={newsletterStat === 1 ? "news_P news_p_show" : "news_p" }>Form submitted succesfully.</p>
                            <p className={newsletterStat === 2 ? "f_news_P f_news_p_show" : "f_news_p" }>Something went wrong, please try again.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="freq">
                <h1>Frequently Asked Questions</h1>
                <p>Everything you need to know about Creath marketplace</p>
                <FAQ question = {"What is Creath?"} answer = 
                {"<p>Creath is a next-generation tech company that delivers Web3, FinTech, PropTech, and Blockchain services. Our company comprises skilled individuals in Blockchain Development, Web Development, Marketing, Visual &amp; Digital Art, Financial Trading, Art Curation, Community Management, Brand Design, and Project Management.</p><br><p>Our platform has an integrated marketplace built on the Ethereum Blockchain. This marketplace will serve as a gallery for artists to display their digitized artworks to a diverse global audience.</p>"}/>

                <FAQ question = {"What Can I do on Creath Marketplace?"} answer = 
                {"<p>The Creath marketplace is designed to be a digital gallery for artists, curators, collectors, and art lovers. The platform is easy to navigate and provides the best user experience. The marketplace attracts a global target audience that will participate in primary sales of our curated collections.</p><br><p>As an artist in partnership with us, your works will be seamlessly available to potential collectors worldwide.</p>"}/>

                <FAQ question = {"What is a Curated Art Collection?"} 
                answer = {"<p>A curated art collection is a collection that is carefully selected by a team of professionals for a particular target audience &mdash;usually because of its cultural value or aesthetics. Creath has a team of curators responsible for determining the collections to be displayed on the marketplace.</p><br><p>The curated collections will include generative art, hand-drawn digital art, and digitized physical artworks.</p>"}/>

                <FAQ question = {"Does Creath Digitize Artworks?"} answer = {"<p>We digitise contemporary artworks into high-resolution Non Fungible Tokens (NFT) on the Blockchain. We also convert digital art to framed physical copies. We provide our primary collectors the original physical art of the NFTs purchased on our platform signed by the artist.</p>"}/>

                <button className='faqButton' onClick={()=>{changePage('/faqs','home')}}>See More</button>
            </div>
            <div className='partners'>
                <h1>Our Partners</h1>
                <div className='Logos'>
                    <LazyLoadImage src={Broken} alt="Broken Egg Sponsor Logo" onClick={()=>{openPage('https://www.brokenegg.io')}} />
                    <LazyLoadImage src={Sport} alt="Sportrex Sponsor Logo" onClick={()=>{openPage('https://www.sportrex.io')}} />
                    <LazyLoadImage src={Optimisms} alt="Sportrex Sponsor Logo" onClick={()=>{openPage('https://www.optimism.io/')}} />
                </div>
            </div>
            <div className='blog'>
                <h1>Blog</h1>
                <div className='posts'>
                    {
                        posts ?
                        posts.map((el,i)=>{
                            if(i <= 2){
                                return  <Blog key={posts[i].id} id = {posts[i].id} cover = {posts[i].cover_image} content = {posts[i].content} title = {posts[i].title} description = {posts[i].description} post = {posts[i]}  />
                            }
                        })
                        :
                        ''
                    }
                </div>
            <button onClick={()=>{navigate('/blogs')}}>See More</button>
            </div>
            <div className='footer'>
                <Footer/>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >   
                <div className='creatorForm'>
                    <h2 className='creatorHeader' ref={(_subtitle) => (subtitle = _subtitle)}>The Gold Ticket</h2>
                    <div className='creatorFormInput'>
                        {
                            whiteStat === 0 || whiteStat === 1 ?
                            <>
                                <p className='formDesc'>Sign up for a waitlist spot</p>
                                <input type="text" placeholder="Full name" onChange={updateWhiteName} />
                                <input type="email" placeholder='Email Address' onChange={updateWhiteMail} />
                                <button className={whiteIsLoading ? "whiteloading" : ""} onClick={handleWhiteListClick}>Submit</button>
                                {
                                    whiteStat === 1 ?
                                    <p className='creatorFormError'>Something went wrong, please try again.</p>
                                    :
                                    ""
                                }
                            </>
                            :
                            <p>Form Submitted Succesfully</p>
                        }
                    </div>
                </div>
                <div className='closeCreatorModal' onClick={()=>{setModalStatus(false)}} role='button'>
                    <LazyLoadImage src={Cancel} alt="Close Modal"/>
                </div>
            </Modal>
        </div>
    )
}

function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
      <svg
        onClick={props.onClick}
        className={`arrow ${
          props.left ? "arrow--left" : "arrow--right"
        } ${disabeld}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    )
}

export default Home;