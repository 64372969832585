import React from 'react';
import './login.css';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import MetaMask from '../../assets/MetaMask.svg.png';
// import Wallet from '../../assets/wallet.svg';
// import { useAccount } from 'wagmi';
// import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';



const Login = (props)=>{
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    Modal.setAppElement('#root');

    const navigate = useNavigate()

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    // const {isConnected} = useAccount()

    function openModal() {
      setIsOpen(true);
    }

  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      subtitle.style.color = '#f00';
    }
  
    function closeModal() {
      setIsOpen(false);
    }



    const metamaskConnect = async ()=>{
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            if (window.ethereum && window.ethereum.isMetaMask){
                await props.connectInjectedWallet()
                closeModal()
            }
            else{
                window.open('https://metamask.app.link/dapp/art.creath.io', '_blank')
            }
            
        }
        else{
            await props.connectInjectedWallet()
            closeModal()
        }
    }

    // const walletConnect = async ()=>{
    //     await props.connectedWalletConnect()
    //     closeModal()
    // }
    
  
    return(
        <div className='loginMain'>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            > 
                <div className='Wallets'>
                    <h2 className='walletsHeader' ref={(_subtitle) => (subtitle = _subtitle)}>Pick a Wallet to connect to Creath</h2>
                    <div className='wallet' onClick={metamaskConnect} role='button'>
                        <LazyLoadImage src={MetaMask} alt="Connect to Metamask"/>
                        <p>MetaMask</p>
                    </div>
                    {/* <div className='wallet' onClick={walletConnect} role='button'>
                        <LazyLoadImage className='walletConnct' src={Wallet} alt="Connect using wallet connect"/>
                        <p>Wallet Connect</p>
                    </div> */}
                </div>
            </Modal>
            <div className='toLogin'>
                <h1>Let's begin with your<br />wallet</h1>
                <button onClick={openModal}>Select a Wallet</button>
            </div>
        </div>
    )
}

export default Login