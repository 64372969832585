import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import './exhibition.css'
import Footer from '../../Components/Footer/footer';
import ExhiCard from '../../Components/ExhibitionCard/ExhibitionCard';


const Exhibitions = ({exhibitions, setCurrentPage})=>{
    const isFeatured = exhibitions

    const isFeaturedVirtual = isFeatured.filter((el)=>{
        return el.type === "Virtual"
    })
    const isFeaturedPhysical = isFeatured.filter((el)=>{
        return el.type === "Physical"
    })
    const isFeaturedHybrid = isFeatured.filter((el)=>{
        return el.type === "Hybrid"
    })
    
    const [exhibition, setExhibitions] = useState(exhibitions)


    useEffect(()=>{
        sessionStorage.setItem("currentPage", "exhibition")
        setCurrentPage("exhibition")
    },[])

    const options = [
        {value: "all", label: "All"},
        {value: "Virtual", label: "Virtual"},
        {value: "Physical", label:"Physical"},
        {value: "Hybrid", label:"Hybrid"}
    ]




    const dropdownChange = (option)=>{
        if(option.value === "all"){
            setExhibitions(isFeatured)
        }
        else if(option.value === "Virtual"){
            setExhibitions(isFeaturedVirtual)
        }
        else if(option.value === "Physical"){
            setExhibitions(isFeaturedPhysical)
        }
        else{
            setExhibitions(isFeaturedHybrid)
        }
    }

    return(
        <div className='exhibitionMain'>
            <div className='exhibitionSubMain'>
                <div className='exhibitionHeroText'>
                    <h1>Exhibitions</h1>
                    <p>Discover and purchase unique and inspiring works of art from talented artists around the world. We invite you to immerse yourself in our virtual, physical and hybrid exhibitions.</p>
                </div>
            </div>
            <div className='upComingExhi'>
                <div className='upComingExhiHeader'>
                    <Select options={options} 
                        className="searchSelect"
                        onChange={dropdownChange}  
                        placeholder="Exhibition Type"
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: 'lightgrey',
                                primary: 'black',
                            },
                        })}
                    />
                </div>
                <div className='exhibitions'>
                    {
                        exhibition.map((e, i )=> {
                            return(
                                <ExhiCard key={exhibition[(exhibition.length - 1) - i].id} exhibition = {exhibition[(exhibition.length - 1) - i]} />
                            )
                        })
                    }
                </div>
            </div>
            <div className='exhifooter'>
                <Footer/>
            </div>
        </div>
    )
}


export default Exhibitions;
