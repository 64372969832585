import React, {useEffect, useState} from "react";
import Web3 from "web3";
import { useNavigate } from 'react-router-dom';
import Loader from "../../assets/white_loader.gif"
import './accountVerification.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const AccountVerification = ({setUserType, setCurrentPage, setIsLoggedIn, disconnectWallet, setCurrentAccount, setCurrentUser})=>{

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [email, setEmail] = useState("");
    const [userName, setUsername] = useState("");
    const [fullName, setName] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const [error, setError] = useState(false)
    const [connectStatus, setConnectStatus] = useState(0)
    const [formError, setFormError] = useState(false)
    const [verificationError, setVerificationError] = useState(false)
    const web3 = new Web3(window.ethereum);

    let account  = localStorage.getItem('account');
    const data =  new FormData()
    data.append("email", email)
    data.append("username", userName)
    data.append("wallet", account)
    data.append("full_name", fullName)

    const data2 = new FormData()
    data2.append("userName", userName)
    data2.append("email", email)
    

    useEffect(()=>{
        if(account){
            
            let data = new FormData()
            data.append('wallet', account)
            fetch("https://creath.tech/api/guest",{
                method: 'POST',
                body: data
            })
            .then(response => response.json())
            .then((data)=>{
                if(data.data.length === 0){
                    setLoading(false)
                }
                else{
                    navigate('/')
                    setUserType(data.data[0].role)
                    localStorage.setItem('role',data.data[0].role )
                    localStorage.setItem('currentUser', JSON.stringify(data.data[0]))
                    setCurrentUser(data.data[0]);
                    sessionStorage.removeItem("verification")
                }
            })
            .catch((e)=>{
                setVerificationError(true)
                setTimeout(()=>{
                    handleCancel();
                }, 5000)
            })        
        }
        else{
            setConnectStatus(connectStatus + 1)
        }
    },[connectStatus, account, setUserType, navigate])

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleName = (e)=>{
        setName(e.target.value)
    }

    const handleEmail = (e)=>{

        if(isValidEmail(e.target.value)){
            setEmail(e.target.value)
            setError(false)
        }
        else{
            setError(true)
        }
    }

    const handleUsername = (e)=>{
        setUsername(e.target.value)
    }


    const handleSubmit = ()=>{
        setSubmitted(true)
        fetch('https://creath.tech/api/users',{
            method: "POST",
            body: data
        })
        .then(response => response.json())
        .then((data)=>{
            setSubmitted(false)
            if(data.message === "User created successfully"){
                fetch('https://creath.tech/api/send-welcome-email',{
                    method: "POST",
                    body: data2
                })
                .then(response => response.json())
                .catch((e)=>{})
                navigate('/')
                localStorage.setItem("currentUser", JSON.stringify(data.data))
                sessionStorage.removeItem("verification")
                localStorage.setItem('role', 'guest')
            }
        })
        .catch((e)=>{
            setSubmitted(false)
            setFormError(true)
            
        })

    }


    const changePage = (page,pageName)=>{
        navigate(page)
        setCurrentPage(pageName)
        localStorage.setItem("currentPage",`${pageName}`)
    }

    const handleCancel = ()=>{
        if(localStorage.getItem('connector') === "injected"){
            web3.setProvider(null);
            localStorage.removeItem('account');
            setCurrentAccount(null)
            localStorage.setItem('isLoggedIn', 'false')
            setIsLoggedIn(false)
            changePage('/','home')
            sessionStorage.removeItem("verification")
          }
          else{
            localStorage.removeItem('account');
            setCurrentAccount(null)
            localStorage.setItem('isLoggedIn', 'false')
            setIsLoggedIn(false)
            disconnectWallet()
            changePage('/','home')
            sessionStorage.removeItem("verification")
          }
    }

    const handlePolicy = ()=>{
        if(localStorage.getItem('connector') === "injected"){
            web3.setProvider(null);
            localStorage.removeItem('account');
            setCurrentAccount(null)
            localStorage.setItem('isLoggedIn', 'false')
            setIsLoggedIn(false)
            changePage('/','home')
            sessionStorage.removeItem("verification")
        }
        else{
            localStorage.removeItem('account');
            setCurrentAccount(null)
            localStorage.setItem('isLoggedIn', 'false')
            setIsLoggedIn(false)
            disconnectWallet()
            changePage('/','home')
            sessionStorage.removeItem("verification")
        }

        navigate("/termsandcondition")
    }


    return(
        <div className="mainVerification">
            <div className={verificationError ? "verificationError showVeriError" : "verificationError"}>
                <p>There was an error logging you in, please try again. redirecting...</p>
            </div>
            <div className="verificationSubMain">
                {
                    loading ? 
                    <div className="loadingBoc">
                        <h1>Please wait while we verify your account</h1>
                        <LazyLoadImage src={Loader} alt="Account verification is progress"/>
                    </div>
                    :
                    <div className="accountForm">
                        <h2 className="fomrHeader">Create an account with us !</h2>
                        <div className="addressContainer">
                            <h3>Wallet Address</h3>
                            <p>{account}</p>
                        </div>
                        <div className="mainForm">
                        <div className="input">
                                <p>Full Name</p>
                                <input  type="text" placeholder="Enter your full name" onChange={handleName}/>
                            </div>
                            <div className="input">
                                <p>Email</p>
                                <input  type="email"  placeholder="Enter your email" onChange={handleEmail}/>
                                {error ? <p className="error">Enter a valid mail</p> : ""}
                            </div>
                            <div className="input">
                                <p>Username</p>
                                <input  type="text" placeholder="Enter your username" onChange={handleUsername}/>
                            </div>
                            <p className="policyText">By clicking sign in you indicate that you have read and agree<br />to our <span onClick={()=>{handlePolicy()}}>Terms of Service and Privacy Policy</span></p>
                            <button disabled = {error || fullName === "" || userName === "" ? true : false} className={submitted ? "whiteloading" : ""} onClick={handleSubmit}>Sign In</button>
                            {formError ? <p className="error">Something went wrong, please try again</p> : ""}
                            <p onClick={handleCancel} className="cancelLogin">X</p>
                        </div>
                    </div>
                }

            </div> 
        </div>
    )
}

export default AccountVerification;