import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import Instagram from '../../assets/insta.png'
import Twitter from '../../assets/twit.png'
import { isMobile, isIPad13, isTablet } from "react-device-detect";
import Footer from "../../Components/Footer/footer";
import ArtCard from "../../Components/ArtCard/artcard";
import ProfileArtCard from "../../Components/ProfileArtCard/profileArt";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './artistProfile.css';
import { useNavigate, useSearchParams } from "react-router-dom";
import ABI from '../../assets/ContractABI.json'
import Loader from '../../assets/white_loader.gif'
import Empty from '../../assets/empty.jpg'
import Loading from '../../assets/Ellipsis.gif';
// import { useEthersProvider } from "../../ethersProviderUtils";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ArtistProfile = (props)=>{
    const [selected, setSelected] = useState("created")
    const [searchParams] = useSearchParams();
    const [personalArts, setPersonalArts] = useState([])
    const [personalCollections, setPersonalCollections] = useState([])
    const [artist, setArtist] = useState({})
    const [currentUserId, setCurrentUserId] = useState("")
    const [connection, setConnection] = useState(0)
    const [collectedArts, setCollected] = useState([])
    const [loading, setLoading] = useState(false)
    const [pageLoad, setPageLoad] = useState(true)
    const [error, setError] = useState(false)
    const id = searchParams.get("id");
    const contractAddress =  '0x013b6f5a3fF3A3259d832b89C6C0adaabe59f8C6';
    const navigate = useNavigate()
    // const ethersProvider = useEthersProvider();


    useEffect(()=>{
        let arts = props.Artss;
        let collections = props.Collections;
        let artists = props.Artists;
        if(localStorage.getItem('currentUser')){
           setCurrentUserId(JSON.parse(localStorage.getItem("currentUser")).id)
        }
        let Arts = []
        let Collections = []

        
        const curr = artists.find((users)=>{
            return `${users.id}` === id
        })
        
        if(curr === undefined){
            setError(true)
            setPageLoad(false)
        }
        else{
            setArtist(curr)
            setPageLoad(false)
        }
        

        // for(let j = 0; j < artists.length; j++){
        //     if(`${artists[j].id}` === id){
        //         setArtist(artists[j])
        //     }
        // }
        for(let i = 0; i < arts.length; i++){
            if(arts[i].artist_id === id){
                Arts.push(arts[i])
            }
        }
        setPersonalArts(Arts)
        for(let i = 0; i < collections.length; i ++){
            if(collections[i].user_id === id){
                Collections.push(collections[i])
            }
        }
        setPersonalCollections(Collections)
    },[])

    useEffect(()=>{
        if(localStorage.getItem("currentUser")){
            if(`${JSON.parse(localStorage.getItem("currentUser")).id}` === id){
                setLoading(true)
                setTimeout(()=>{
                    getUserArts()
                },1000)
            }
        
            else{
                setLoading(false)
            }
        }
        else{
            setLoading(false)
        }
    },[connection])

    
    const getUserArts = async ()=>{
        if(`${JSON.parse(localStorage.getItem("currentUser")).id}` === id){
            if(localStorage.getItem('connector') === 'injected'){
                if(props.library.provider){
                    const provider = new ethers.providers.Web3Provider(props.library.provider);
                    const ContractInstance = new ethers.Contract(contractAddress, ABI, provider)
                    let Txn = await ContractInstance.getCollectorData(props.wallet._address)
                    const test = []
                    if(Txn.length !== 0){
                        for(let i = 0; i < Txn.length; i++){
                            let nft_address = Txn[i].nft_address;
                            let nft_id =  `${parseInt(Txn[i].tokenId._hex, 16)}`
                            let jsonData =  {
                                "id" : nft_id,
                                "nft_address": nft_address
                            }
                            test.push(jsonData)
                        }
                        fetch('https://creath.tech/api/arts/byIds', {
                            method: 'POST',
                            body: JSON.stringify(test)
                        })
                        .then(response => response.json())
                        .then((data)=>{
                            setCollected(data.data[0])
                            setLoading(false)
                        })
                        .catch((e)=>{
                            setCollected([])
                            setLoading(false)
                        })
                    }
                    else{
                        setLoading(false)
                    }
                }
                else{
                    setConnection(connection + 1)
                }
            }
            // else{
            //     const ContractInstance = new ethers.Contract(contractAddress, ABI, ethersProvider)
            //     let Txn = await ContractInstance.getCollectorData(localStorage.getItem('account'))
            //     const test = []
            //     if(Txn.length !== 0){
            //         for(let i = 0; i < Txn.length; i++){
            //             let nft_address = Txn[i].nft_address;
            //             let nft_id =  `${parseInt(Txn[i].tokenId._hex, 16)}`
            //             let jsonData =  {
            //                 "id" : nft_id,
            //                 "nft_address": nft_address
            //             }
            //             test.push(jsonData)
            //         }
            //         fetch('https://creath.tech/api/arts/byIds', {
            //             method: 'POST',
            //             body: JSON.stringify(test)
            //         })
            //         .then(response => response.json())
            //         .then((data)=>{
            //             setCollected(data.data[0])
            //             setLoading(false)
            //         })
            //     }
            //     else{
            //         setLoading(false)
            //     }
            // }
        }
    }

    const handleOptionClick = (n)=>{
        setSelected(n)
    }

    const openCollection = (id)=>{
        navigate(`/collectionProfile?id=${id}`)
    }

    const openInstagram = ()=>{
        window.open(`https://instagram.com/${artist.instagram}`, "_blank")
    }

    const openTwitter = ()=>{
        window.open(`https://twitter.com/${artist.twitter}`, "_blank")
    }

    if(pageLoad){
        <div className='mainLoading'>
            <LazyLoadImage src={Loading} alt="Loading screen"/>
        </div>
    }
    else{
        return(
            <div className="profileMain artistProfile">
                {
                    error ?
                        <div className='four'>
                            <h1>404</h1>
                            <p>Artist not found, you can reload or go back to home</p>
                            <button onClick={()=>{navigate('/')}}>Go to home</button>
                        </div>
                    :
                    <>
                        <div className="profileInfo">
                            <div className="info">
                                <LazyLoadImage className="profileImage" src={`${artist.profile_image}`} alt="Profile Display"/>
                                <div className="infoText">
                                    <h1>{artist.username}</h1>
                                    <p>{artist.bio}</p>
                                    <div className="subInfo">
                                        <p><strong>{personalArts.length}</strong> Artworks</p>
                                        <p><strong>{personalCollections.length}</strong> Collection</p>
                                    </div>
                                    <div className="socialMediaInfo">
                                        <LazyLoadImage src={Instagram} onClick={openInstagram} alt="Instagram social media link"/>
                                        <LazyLoadImage src={Twitter} onClick={openTwitter} alt="Twitter social media link"/>
                                    </div>
                                </div>
                                <button onClick={()=>{navigate('/editProfile')}} className={currentUserId == `${artist.id}` ? "" : "removeButton"}>Edit Profile</button>
                            </div>
                        </div>
                        <div className="profileWorks">
                            <div className="workHeaders">
                                <button className={selected === "created" ? "clickedOption" : ""} onClick={()=>{handleOptionClick("created")}}>
                                    Created
                                </button>
                                <button className={selected === "collected" ? "clickedOption" : ""} onClick={()=>{handleOptionClick("collected")}}>
                                    Collections
                                </button>
                            </div>
                            <div className="works">
                                <div className={selected === "created" ? "collectedWorks showWorks" : "collectedWorks"}>
                                    {
                                        personalArts.length === 0 ?
                                        <div className="empty">
                                            <p className="emptyFavorites">No artworks</p>
                                            <LazyLoadImage src={Empty} alt="You have not bought any artwork"/>
                                        </div>
                                        :
                                        <div className="worksSlides">
                                            <CarouselProvider
                                                naturalSlideWidth={100}
                                                naturalSlideHeight={125}
                                                totalSlides={personalArts.length}
                                                visibleSlides={isMobile && isIPad13 ? 3 : isMobile && isTablet ? 3 : isMobile ? 1 : 3}
                                            >
                                                <Slider>
                                                    {
                                                        personalArts.map((el,i)=>{
                                                            return(
                                                                <Slide key={personalArts[i].id} index={i}>
                                                                    <ArtCard artistName={personalArts[i].artist_name} Art={personalArts[i]} physical = {personalArts[i].has_physical_copy} key={personalArts[i].id} id={personalArts[i].id} img={`${personalArts[i].art_image}`} title={personalArts[i].name} description={personalArts[i].description}  artist={personalArts[i].artist_id} price={personalArts[i].price} style={{color: "red"}} setProduct = {props.setProduct} setArtist = {props.setArtist}/>
                                                                </Slide>
                                                            )
                                                        })
                                                    }
                                                </Slider>
                                                <ButtonBack>{'<'}</ButtonBack>
                                                <ButtonNext>{'>'}</ButtonNext>
                                            </CarouselProvider>
                                        </div>
                                    }
                                </div>
                                <div className={selected === "collected" ? "createdWorks showWorks" : "createdWorks"}>
                                    {
                                        personalCollections.length === 0 ?
                                        <div className="empty">
                                            <p className="emptyFavorites">No Collections</p>
                                            <LazyLoadImage src={Empty} alt="You have not bought any artwork"/>
                                        </div>
                                        :
                                        <div className="worksSlides">
                                            <CarouselProvider
                                                naturalSlideWidth={100}
                                                naturalSlideHeight={125}
                                                totalSlides={personalCollections.length}
                                                visibleSlides={isMobile && isIPad13 ? 3 : isMobile && isTablet ? 3 : isMobile ? 1 : 3}
                                            >
                                                <Slider>
                                                    {
                                                        personalCollections.map((el,i)=>{
                                                            return(
                                                                <Slide key={personalCollections[i].id} index={i}>
                                                                    <div className="collectionSlide" onClick={()=>{openCollection(personalCollections[i].id)}}>
                                                                        <LazyLoadImage src={`${personalCollections[i].cover_image}`} alt={personalCollections[i].name}/>
                                                                        <div className="collectionName">
                                                                            <p>
                                                                                {personalCollections[i].name}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </Slide>
                                                            )
                                                        })
                                                    }
                                                </Slider>
                                                <ButtonBack>{'<'}</ButtonBack>
                                                <ButtonNext>{'>'}</ButtonNext>
                                            </CarouselProvider>
                                        </div>
                                    }
                                </div>
                                <div className={currentUserId == `${artist.id}` ? "artistWorks" : "none"}>
                                    <h1 className='title'>Collected Artworks</h1>
                                    {
                                        loading ?
                                        <div className="artworkLoad">
                                            <p>Getting your artworks</p>
                                            <LazyLoadImage src={Loader} alt="Getting loading information"/>
                                        </div>
                                        :
            
                                        <div className="collectedWorks showWorks">
                                            {
                                                collectedArts.length === 0 ?
                                                <div className="empty">
                                                    <p className="emptyFavorites">You have not bought any artworks</p>
                                                    <LazyLoadImage src={Empty} alt="You have not bought any artwork"/>
                                                </div>
                                                :
                                                <div className="worksSlides">
                                                    <CarouselProvider
                                                        naturalSlideWidth={100}
                                                        naturalSlideHeight={125}
                                                        totalSlides={collectedArts.length}
                                                        visibleSlides={isMobile && isIPad13 ? 3 : isMobile && isTablet ? 3 : isMobile ? 1 : 3}
                                                    >
                                                        <Slider>
                                                            {
                                                                collectedArts.map((el,i)=>{
                                                                    return(
                                                                        <Slide key={collectedArts[i].id} index={i}>
                                                                            <ProfileArtCard artistName = {collectedArts[i].artist_name} Art={collectedArts[i]} physical = {collectedArts[i].has_physical_copy} key={collectedArts[i].id} id={collectedArts[i].id} img={`${collectedArts[i].art_image}`} title={collectedArts[i].name} description={collectedArts[i].description}  artist={collectedArts[i].artist_id} price={collectedArts[i].price} style={{color: "red"}} setProduct = {props.setProduct} setArtist = {props.setArtist}/>
                                                                        </Slide>
                                                                    )
                                                                })
                                                            }
                                                        </Slider>
                                                        <ButtonBack>{'<'}</ButtonBack>
                                                        <ButtonNext>{'>'}</ButtonNext>
                                                    </CarouselProvider>
                                                </div>
                                            }
                                        </div>
            
                                    }
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </>
                }
                
            </div>
        )
    }
    
}

export default ArtistProfile