import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import { Web3ReactProvider } from '@web3-react/core';
// import { ethers } from 'ethers';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
// import { EthereumClient,w3mConnectors,w3mProvider } from '@web3modal/ethereum';
// import { Web3Modal } from '@web3modal/react';
// import { configureChains, createConfig, WagmiConfig } from 'wagmi';
// import { mainnet, optimism } from 'wagmi/chains';
import {BrowserRouter as Router} from 'react-router-dom';
// import {Buffer} from 'buffer';
// import {infuraProvider} from 'wagmi/providers/infura'


// window.Buffer = window.Buffer || require("buffer").Buffer;
// Buffer.from('anything','base64');

// function getLibrary(provider) {
//   const library = new ethers.providers.Web3Provider(provider);
//   library.pollingInterval = 12000;
//   return library;
// }

// const chains = [mainnet, optimism]
// const projectId = '2b6808b96b13ea016ba94df505227394'

// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
// const wagmiConfig = createConfig({
//   autoConnect: true,
//   enableNetworkView: true,
//   connectors: w3mConnectors({ projectId, chains }),
//   publicClient
// })
// const ethereumClient = new EthereumClient(wagmiConfig, chains)





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <Web3ReactProvider getLibrary={getLibrary}>
      <WagmiConfig config={wagmiConfig}> */}
        <Router>
          <App />
        </Router>
      {/* </WagmiConfig>
    </Web3ReactProvider>
    <Web3Modal projectId={projectId} ethereumClient={ethereumClient} /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
