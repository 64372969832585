import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import ABI from './ContractABI.json'
import './profile.css';
import Twitter from './twitter.png'
import Footer from "../Footer/footer";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { isMobile, isIPad13, isTablet } from "react-device-detect";
import ArtCard from "../ArtCard/artcard";
import ProfileArtCard from "../ProfileArtCard/profileArt";
import { useNavigate } from "react-router-dom";
import Loader from '../../assets/white_loader.gif';
import Placeholder from './placeholder.png';
// import { useEthersProvider } from "../../ethersProviderUtils";
import Empty from './empty.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Profile = (props)=>{
    const [selected, setSelected] = useState("created")
    const [personalArts, setArts] = useState([])
    const [collectedArts, setCollected] = useState([])
    const [loading, setLoading] = useState(false)
    const [connection, setConnection] = useState(0)
    const profileInfo = JSON.parse(localStorage.getItem('currentUser'));
    const contractAddress =  '0x013b6f5a3fF3A3259d832b89C6C0adaabe59f8C6';
    // const ethersProvider = useEthersProvider();
    useEffect(()=>{
        fetch(`https://creath.tech/api/users/favorites/${profileInfo.id}`)
        .then(response => response.json())
        .then((data)=>{
           setArts(data.data)
        })
    },[profileInfo.id])

    useEffect(()=>{
        setLoading(true)
        setTimeout(()=>{
            getUserArts()
        },1000)
    },[connection])

    const getUserArts = async ()=>{
        // let collectedArts = []
        if(localStorage.getItem('connector') === 'injected'){
            if(props.library.provider){
                const provider = new ethers.providers.Web3Provider(props.library.provider);
                const ContractInstance = new ethers.Contract(contractAddress, ABI, provider)
                let Txn = await ContractInstance.getCollectorData(props.wallet._address)
                const test = []
                if(Txn.length !== 0){
                    for(let i = 0; i < Txn.length; i++){
                        let nft_address = Txn[i].nft_address;
                        let nft_id =  `${parseInt(Txn[i].tokenId._hex, 16)}`
                        let jsonData =  {
                            "id" : nft_id,
                            "nft_address": nft_address
                        }
                        test.push(jsonData)
                    }
                    fetch('https://creath.tech/api/arts/byIds', {
                        method: 'POST',
                        body: JSON.stringify(test)
                    })
                    .then(response => response.json())
                    .then((data)=>{
                        setCollected(data.data[0])
                        setLoading(false)
                    })
                }
                else{
                    setLoading(false)
                }
            }
            else{
                setConnection(connection + 1)
            }
        }
        // else{
        //     const ContractInstance = new ethers.Contract(contractAddress, ABI, ethersProvider)
        //     let Txn = await ContractInstance.getCollectorData(localStorage.getItem('account'))
        //     const test = []
        //     if(Txn.length !== 0){
        //         for(let i = 0; i < Txn.length; i++){
        //             let nft_address = Txn[i].nft_address;
        //             let nft_id =  `${parseInt(Txn[i].tokenId._hex, 16)}`
        //             let jsonData =  {
        //                 "id" : nft_id,
        //                 "nft_address": nft_address
        //             }
        //             test.push(jsonData)
        //         }
        //         fetch('https://creath.tech/api/arts/byIds', {
        //             method: 'POST',
        //             body: JSON.stringify(test)
        //         })
        //         .then(response => response.json())
        //         .then((data)=>{
        //             setCollected(data.data[0])
        //             setLoading(false)
        //         })
        //     }
        //     else{
        //         setLoading(false)
        //     }
        // }
    }
 
    const handleOptionClick = (n)=>{
        setSelected(n)
    }

    const navigate = useNavigate()

    const changePange = (n)=>{
        navigate(n)
    }

    return(
        <div className="profileMain">
            <div className="profileInfo">
                <div className="info">
                    <LazyLoadImage className ="profileImage" src={profileInfo.profile_image && profileInfo.profile_image !== null ? `${profileInfo.profile_image}` : Placeholder} alt="Profile Display Picture"/>
                    <div className="infoText">
                        <h1>{profileInfo.username}</h1>
                        <p>{profileInfo.bio}</p>
                        <div className="subInfo"> 
                            <p><strong>{collectedArts.length}</strong> Collected Artworks</p>
                        </div>
                        <div className="collectorSocialMediaInfo">
                            <button onClick={()=>{changePange('/editProfile')}}>Edit Profile</button>
                            <LazyLoadImage src={Twitter} alt="Twitter social media link"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="profileWorks">
                <div className="workHeaders">
                    <button className={selected === "created" ? "clickedOption" : ""} onClick={()=>{handleOptionClick("created")}}>
                        Collections
                    </button>
                    <button className={selected === "collected" ? "clickedOption" : ""} onClick={()=>{handleOptionClick("collected")}}>
                        Favorites
                    </button>
                </div>
                <div className="works artistProfile">
                    {
                
                        <div className={selected === "created" ? "collectedWorks showWorks" : "createdWorks"}>
                            {
                                loading ?
                                <div className="artworkLoad">
                                    <p>Getting your artworks</p>
                                    <LazyLoadImage src={Loader} alt="Getting loading information"/>
                                </div>
                                :
                                collectedArts.length === 0 ?
                                <div className="empty">
                                    <p className="emptyFavorites">You have not bought any artworks</p>
                                    <LazyLoadImage src={Empty} alt="You have not bought any artwork"/>
                                </div>
                                :
                                <div className="worksSlides">
                                    <CarouselProvider
                                        naturalSlideWidth={100}
                                        naturalSlideHeight={125}
                                        totalSlides={collectedArts.length}
                                        visibleSlides={isMobile && isIPad13 ? 3 : isMobile && isTablet ? 3 : isMobile ? 1 : 3}
                                    >
                                        <Slider>
                                            {
                                                collectedArts.map((el,i)=>{
                                                    if(collectedArts[i]){
                                                        return(
                                                            <Slide key={i} index={i}>
                                                                <ProfileArtCard artistName = {collectedArts[i].artist_name} Art={collectedArts[i]} physical = {collectedArts[i].has_physical_copy} key={collectedArts[i].id} id={collectedArts[i].id} img={`${collectedArts[i].art_image}`} title={collectedArts[i].name} description={collectedArts[i].description}  artist={collectedArts[i].artist_id} price={collectedArts[i].price} style={{color: "red"}} setProduct = {props.setProduct} setArtist = {props.setArtist}/>
                                                            </Slide>
                                                        )
                                                    }
                                                    return null
                                                })
                                            }
                                        </Slider>
                                        <ButtonBack>{'<'}</ButtonBack>
                                        <ButtonNext>{'>'}</ButtonNext>
                                    </CarouselProvider>
                                </div>
                            }
                        </div>

                    }
                    <div className={selected === "collected" ? "createdWork showWork" : "collectedWorks"}>
                        {
                            personalArts.length === 0 ?
                            <div className="empty">
                                    <p className="emptyFavorites">No favoirte artworks</p>
                                    <LazyLoadImage src={Empty} alt="You have not bought any artwork"/>
                                </div>
                            :
                            <div className="worksSlides">
                                <CarouselProvider
                                    naturalSlideWidth={100}
                                    naturalSlideHeight={125}
                                    totalSlides={personalArts.length}
                                    visibleSlides={isMobile && isIPad13 ? 3 : isMobile && isTablet ? 3 : isMobile ? 1 : 3}
                                >
                                    <Slider>
                                        {
                                            personalArts.map((el,i)=>{
                                                return(
                                                    <Slide key={i} index={i}>
                                                        <ArtCard artistName = {personalArts[i].artist_name} Art={personalArts[i]} physical = {personalArts[i].has_physical_copy} key={personalArts[i].id} id={personalArts[i].id} img={`${personalArts[i].art_image}`} title={personalArts[i].name} description={personalArts[i].description}  artist={personalArts[i].artist_id} price={personalArts[i].price} style={{color: "red"}} setProduct = {props.setProduct} setArtist = {props.setArtist}/>
                                                    </Slide>
                                                )
                                            })
                                        }
                                    </Slider>
                                    <ButtonBack>{'<'}</ButtonBack>
                                    <ButtonNext>{'>'}</ButtonNext>
                                </CarouselProvider>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </div> 
    )
}

export default Profile