import React, { useState, useEffect } from 'react';
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import './exhibitionProfile.css'
import Footer from '../../Components/Footer/footer';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import SlideCard from '../../Components/SliderCard/slidercard';
import Cancel from '../../assets/cancel.png';
import Modal from 'react-modal';
import { LazyLoadImage } from 'react-lazy-load-image-component';




const ExhibitionProfile = (props)=>{
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const navigate = useNavigate();
    const collections = props.collections.filter((el)=>{
        return el.exhibition_id === id
    })
    const arts = props.Arts
    const exhibitionProfiles = props.exhibitions.filter((el)=>{
        return `${el.id}` === `${id}`
    })
    const exhibitionProfile = exhibitionProfiles[0];
    const images = exhibitionProfiles[0].images.split(",");
    const [currentSlide2, setCurrent2Slide] = useState(0)
    const [loaded2, set2Loaded] = useState(false)
    const [Arts, setArts] = useState('',{});
    const [ShowAll, setShowAll] = useState(false)


    const [sliderRef2, instanceRef2] = useKeenSlider({
        loop:true,
        slides: {
            perView: isMobile ? 1 : 2,
        },
    initial: 0,
        slideChanged(slider) {
        setCurrent2Slide(slider.track.details.rel)
        },
        created() {
        set2Loaded(true)
        },
    },[
        (slider) => {
          let timeout
          let mouseOver = false
          function clearNextTimeout() {
            clearTimeout(timeout)
          }
          function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
              slider.next()
            }, 2000)
          }
          slider.on("created", () => {
            slider.container.addEventListener("mouseover", () => {
              mouseOver = true
              clearNextTimeout()
            })
            slider.container.addEventListener("mouseout", () => {
              mouseOver = false
              nextTimeout()
            })
            nextTimeout()
          })
          slider.on("dragStarted", clearNextTimeout)
          slider.on("animationEnded", nextTimeout)
          slider.on("updated", nextTimeout)
        },
    ])


    useEffect(()=>{
        let temp = []

        for(let i = 0; i < collections.length; i++){
            for (let j =0; j < arts.length; j++){
                if(`${arts[j].collection_id}` === `${collections[i].id}`){
                    temp.push(arts[j])
                }
            }
        }
        temp = temp.sort(function(a, b) { return a.nft_id - b.nft_id })
        setArts(temp.map((el,i)=>{
            if(!ShowAll){
                if(i < 6){
                    return(
                        <SlideCard artistName = {temp[i].artist_name} physical = {temp[i].has_physical_copy} key={temp[i].id} id={temp[i].id} img={`${temp[i].art_image}`} title={temp[i].name} description={temp[i].description} artist={temp[i].artist_id} price={temp[i].price} style={{color: "red"}} setProduct = {props.setProduct} setArtist = {props.setArtist} Art={temp[i]} />            
                    )
                }
            }
            else{
                return(
                    <SlideCard artistName = {temp[i].artist_name} physical = {temp[i].has_physical_copy} key={temp[i].id} id={temp[i].id} img={`${temp[i].art_image}`} title={temp[i].name} description={temp[i].description} artist={temp[i].artist_id} price={temp[i].price} style={{color: "red"}} setProduct = {props.setProduct} setArtist = {props.setArtist} Art={temp[i]} />            
                )
            }
        }))
    },[ShowAll])

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    Modal.setAppElement('#root');

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const setModalStatus = ()=>{
        setIsOpen(true);
    }


    const showAll = ()=>{
        if(ShowAll === true){
            setShowAll(false)
        }
        else{
            setShowAll(true);
        }
    }

    const register = ()=>{
        window.open(exhibitionProfile.link, "_blank")
    }

    return(
        <div className="exhiProfileMain">
            <div className='exhibitionprofileSubMain'>
                <div className='exhibitionprofileHeroText'>
                    <div className='exhibitionDesc'>
                        <h1>{exhibitionProfile.name}</h1>
                        <p>{exhibitionProfile.description}</p>
                        <span onClick={setModalStatus} className={exhibitionProfile.description.length >= 500 ? "show_more" : "no_show_more" }>...more</span>
                        {
                            exhibitionProfile.is_featured === "1" || `${exhibitionProfile.id}` === `${8}` ? 
                            <></>
                            :
                            <button onClick={register}>Click Here to Register</button>
                        }
                    </div>
                    <LazyLoadImage src={exhibitionProfile.cover_image} alt={exhibitionProfile.name}/>
                </div>
                <div className='exhiProfileslider'>
                    <div className={ShowAll ? "exhiArtworks showAll" : "exhiArtworks"}>
                        {Arts}
                    </div>
                    <button onClick={showAll}>{ShowAll ? "See Less" : "See More"}</button>
                </div>
            </div>
            {
                exhibitionProfile.type === "Virtual" ?
                <div className={exhibitionProfile.is_done === "1" && exhibitionProfile.id !== 6  ? 'metaverse' : "no_show"}>
                    <button className='faqButton' onClick={()=>{window.open(`${exhibitionProfile.link}`, "_blank")}}>View in Metaverse</button>
                </div>
                : exhibitionProfile.type === "Physical" ?
                <div className={exhibitionProfile.is_done === "1" ? 'exhiProfilePictureGallery': "no_show"}>
                <h1>Picture Gallery</h1>
                {
                    exhibitionProfile.images ?
                    <>
                        <div ref={sliderRef2} className="keen-slider exhiProfileslider2">
                        {
                            images.map((el,i)=>{
                                return(
                                    <div key={i * 2} className="keen-slider__slide exhiProfileslide2">
                                        <LazyLoadImage src={`${images[i]}`} alt="Exhibition sample "/>
                                    </div>
                                )
                            })
                        }
                        </div>
                        <div className='sub-arrows'>
                            {loaded2 && instanceRef2.current && (
                                <>
                                    <Arrow
                                    left
                                    onClick={(e) =>
                                        e.stopPropagation() || instanceRef2.current?.prev()
                                    }
                                    disabled={currentSlide2 === 0}
                                    />

                                    <Arrow
                                    onClick={(e) =>
                                        e.stopPropagation() || instanceRef2.current?.next()
                                    }
                                    disabled={
                                        currentSlide2 ===
                                        exhibitionProfile.images.length - 1
                                    }
                                    />
                                </>
                            )}
                        </div>
                    </>
                    :
                    ""
                }
                </div>
                :
                <>
                    <div className={exhibitionProfile.is_done === "1" ? 'exhiProfilePictureGallery': "no_show"}>
                        <h1>Picture Gallery</h1>
                        {
                            exhibitionProfile.images ?
                            <>
                                <div ref={sliderRef2} className="keen-slider exhiProfileslider2">
                                {
                                    images.map((el,i)=>{
                                        return(
                                            <div key={i * 2} className="keen-slider__slide exhiProfileslide2">
                                                <LazyLoadImage src={`${images[i]}`} alt="Exhibition sample "/>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                                <div className='sub-arrows'>
                                    {loaded2 && instanceRef2.current && (
                                        <>
                                            <Arrow
                                            left
                                            onClick={(e) =>
                                                e.stopPropagation() || instanceRef2.current?.prev()
                                            }
                                            disabled={currentSlide2 === 0}
                                            />

                                            <Arrow
                                            onClick={(e) =>
                                                e.stopPropagation() || instanceRef2.current?.next()
                                            }
                                            disabled={
                                                currentSlide2 ===
                                                exhibitionProfile.images.length - 1
                                            }
                                            />
                                        </>
                                    )}
                                </div>
                            </>
                            :
                            ""
                        }
                    </div>
                    <div className={exhibitionProfile.is_done === "1" && exhibitionProfile.id !== 6 ? 'metaverse' : "no_show"}>
                        <button className='faqButton' onClick={()=>{window.open(`${exhibitionProfile.link}`, "_blank")}}>View in Metaverse</button>
                    </div>
                </>
            }
            <div className='exhibitionProfileFooter'>
                <Footer/>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >   
                <div className='exhiModal'>
                    <h2 className='exhiModalHeader' ref={(_subtitle) => (subtitle = _subtitle)}>{exhibitionProfile.name}</h2>
                    <p>{exhibitionProfile.description}</p>

                </div>
                <div className='closeExhiModal' onClick={closeModal} role='button'>
                    <LazyLoadImage src={Cancel} alt="Close Modal"/>
                </div>
            </Modal>
        </div>
    )
}

function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
      <svg
        onClick={props.onClick}
        className={`arrow ${
          props.left ? "arrow--left" : "arrow--right"
        } ${disabeld}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    )
}


export default ExhibitionProfile