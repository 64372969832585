import React, {useState, useEffect} from "react";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Footer from "../../Components/Footer/footer";
import { useNavigate } from "react-router-dom";
import './artists.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const Artists = (props)=>{
    const staticArtists = props.artists;
    const [artists, setArtist] = useState(props.artists)
    const [pageNumber, setPageNumber] = useState(0)
    const [currentPagin, setCurrentPagin] = useState(1)
    const [artist, setArtists] = useState([])
    const navigate = useNavigate()

    const changePage = (page,pageName)=>{
        navigate(page)
        props.setCurrentPage(pageName)
        localStorage.setItem("currentPage",`${pageName}`)
    }

    useEffect(()=>{
        let startIndex = (currentPagin * 15) - 15;
        let endIndex = (currentPagin * 15) - 1
        setPageNumber(Math.ceil(artists.length/15));
        
        setArtists(
            artists.map((user,i)=>{
                if((i >= startIndex) && (i <= endIndex)){
                    return(
                        <div key={artists[i].id} className="nft" onClick={()=>{changePage(`/artistProfile?id=${artists[i].id}`,"home")}} role="button">
                            <LazyLoadImage  src={`${(artists[i].profile_image.substr(0, artists[i].profile_image.lastIndexOf(".")) + ".webp").slice(0,29) + "q_30/" + (artists[i].profile_image.substr(0, artists[i].profile_image.lastIndexOf(".")) + ".webp").slice(29)}`} alt={artists[i].username}/>
                            <div className="Text">
                                <p className="art_name">{artists[i].username}</p>
                            </div>
                        </div> 
                    )
                }
            })
        )

    },[currentPagin,artists])

    const paginChange = (event, value)=>{
        setCurrentPagin(value);
    }


    const searchChange = (event)=>{
        setArtist(staticArtists.filter((arr)=>{
            return arr.username.toLowerCase().includes(event.target.value.toLowerCase())
        }))
    }



    return(
        <div className="artistsMain">
            <div className="artistsHero">
                <h1>Our Artists</h1>
                <p>We have some of the best artists from around the world</p>
                <input type="text" placeholder="Search for artist" onChange={searchChange}/>
            </div>
            <div className="artistsContainer">
                <div className='arts'>
                    {artist}
                </div>
                <div className='pagins'>
                    <Stack spacing={2}>
                        <Pagination count={pageNumber} variant="outlined" shape="rounded" onChange={paginChange} />
                    </Stack>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Artists