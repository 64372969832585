import React from "react";
import "./ExhibitionCard.css";
import { useNavigate } from 'react-router-dom';

const ExhiCard = (props)=>{


    const monthNames = ["January", "February", "March", "April", "May", "June",
         "July", "August", "September", "October", "November", "December"
    ]

    const navigate = useNavigate();


    let sectionStyle = {
        backgroundImage: `url(${props.exhibition.cover_image.substr(0, props.exhibition.cover_image.lastIndexOf(".")) + ".webp"})`,
        backgroundSize: "cover",
        backgroundPosition:"center",
        backgroundRepeat: "no-repeat"
    };

    const openExhibition = ()=>{
        navigate(`/exhibitionPage/${props.exhibition.name}?id=${props.exhibition.id}`)
    }


    return(
        <div className="exhiCardMain" style={sectionStyle} onClick={openExhibition} role="button">
            <div className="exhiCardDetails">
                <h3>{props.exhibition.name}</h3>
                <p className="detailType">{props.exhibition.type === "Virtual" ? "Virtual Exhibition" : `${props.exhibition.type} Exhibition` }</p>
                <p className="detailDay">{props.exhibition.date}</p>
            </div>
        </div>
    )
}

export default ExhiCard