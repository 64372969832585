import React, { useState } from "react";
import Footer from "../../Components/Footer/footer";
import { CountryDropdown, RegionDropdown} from 'react-country-region-selector';
import { useNavigate } from "react-router-dom";
import './editProfile.css'
import PublitioAPI from 'publitio_js_sdk'
import Placeholder from '../../assets/placeholder.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const Edit = ()=>{
    const profileInfo = JSON.parse(localStorage.getItem("currentUser"))
    const [profileImage, setProfileImage] = useState(profileInfo.profile_image)
    const [country, setCountry] = useState(profileInfo.country);
    const [state, setState] = useState(profileInfo.state)
    const [name, setName] = useState(profileInfo.full_name)
    const [email, setEmail] = useState(profileInfo.email)
    const [userName, setUserName] = useState(profileInfo.username)
    const [bio, setBio] = useState(profileInfo.bio)
    const [twitter, setTwitter] = useState(profileInfo.twitter)
    const [instagram, setInstagram] = useState(profileInfo.instagram)
    const [portfolio, setPorfolio] = useState(profileInfo.portfolio)
    const [address, setAddress] = useState(profileInfo.address)
    const [emailError, setEmailError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [imagePlaceholder, setImagePlaceholder] = useState("")

    const publitio = new PublitioAPI('tfG0r5ubzgLenzqqP589', '3ypadj04hiRp0mj3s0b6uS4kzzPRzwzp')

    const data = new FormData()
    const navigate = useNavigate()


    if(country !== profileInfo.country){
        data.append("country", country)
    }

    if(state !== profileInfo.state){
        data.append("state", state)
    }

    if(name !== profileInfo.full_name){
        data.append("full_name", name)
    }

    if(email !== profileInfo.email){
        data.append("email", email)
    }

    if(address !== profileInfo.address){
        data.append("address", address)
    }

    if(bio !== profileInfo.bio){
        data.append("bio", bio)
    }

    if(userName !== profileInfo.username){
        data.append("username", userName)
    }

    if(twitter !== profileInfo.twitter){
        data.append("twitter", twitter)
    }

    if(instagram !== profileInfo.instagram){
        data.append("instagram", instagram)
    }

    if(portfolio !== profileInfo.portfolio){
        data.append("portfolio", portfolio)
    }



    const fileHandler = (e)=>{
        setProfileImage(e.target.files[0]);
        setImagePlaceholder(URL.createObjectURL(e.target.files[0]));
    }

    const handleUsername = (e)=>{
        setUserName(e.target.value)
    }

    const handleName = (e)=>{
        setName(e.target.value)
    }

    const handleBio = (e)=>{
        setBio(e.target.value)
    }

    const handleTwitter = (e)=>{
        setTwitter(e.target.value)
    }

    const handleInsta = (e)=>{
        setInstagram(e.target.value)
    }

    const handleAddress = (e)=>{
        setAddress(e.target.value)
    }

    const handlePortfolio = (e)=>{
        setPorfolio(e.target.value)
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleEmail = (e)=>{

        if(isValidEmail(e.target.value)){
            setEmail(e.target.value)
            setEmailError(false)
        }
        else{
            setEmailError(true)
        }
    }

    function handleSubmit (){
        setLoading(true)

        if(profileImage instanceof File){
            publitio.uploadFile(profileImage, 'file')
            .then((result) => {
                data.append("profile_image", result.url_preview) 
                fetch(`https://creath.tech/api/users/${profileInfo.id}`,{
                method: "POST",
                body: data
                })
                .then(response => response.json())
                .then((data)=>{
                    localStorage.setItem("currentUser", JSON.stringify(data.data))
                    if(localStorage.getItem('role') === "artist"){
                        navigate(`/artistProfile?id=${profileInfo.id}`)
                    }
                    else{
                        navigate("/profile")
                    }

                })
                .catch((e)=>{
                    
                    setLoading(false)
                })
            })
            .catch((error) => { })
        }
        else{
    
            fetch(`https://creath.tech/api/users/${profileInfo.id}`,{
                method: "POST",
                body: data
            })
            .then(response => response.json())
            .then((data)=>{
                localStorage.setItem("currentUser", JSON.stringify(data.data))
                if(localStorage.getItem('role') === "artist"){
                    navigate(`/artistProfile?id=${profileInfo.id}`)
                }
                else{
                    navigate("/profile")
                }
            })
            .catch((e)=>{
                setLoading(false)
            })
        }
    }

    

    return(
        <div className="mainEdit">
            <div className="editSubMain">
                <h2>Edit Profile</h2>
                <div className="editForm">
                    <div className="form1">
                        <div className="form1Text">
                            <input type="text" className="editInput" defaultValue={profileInfo.full_name} onChange={handleName} />
                            <input type="text" className="editInput" defaultValue={profileInfo.username} onChange={handleUsername} />
                            <input type="email" className="editInput" defaultValue={profileInfo.email} onChange={handleEmail}/>
                            {emailError ? <p className="error">Enter a valid mail</p> : ""}
                            <input type="text" className="editInput" defaultValue={profileInfo.bio} placeholder="Bio" onChange={handleBio}/>
                        </div>
                        <div className="form1Image">
                            <div>
                                <LazyLoadImage src={profileImage && typeof profileImage === 'string' ? `${profileImage}` : imagePlaceholder === "" ? Placeholder : imagePlaceholder} className = {!profileImage ? "remove" : ""} alt="Depiction of the user"/>
                            </div>
                            <p>Choose profile picture<br />Square cropped image file type</p>
                            <input type="file" placeholder="Upload Image" accept="image/*" onChange={fileHandler} name="file_upload" />
                        </div>
                    </div>
                    <div className="form2">
                        <div className={localStorage.getItem('role') === "artist" ? "socialForm" : "removeSocialForm"}>
                            <p>Social Media Handles</p>
                            <input type="text" className="editInput" defaultValue={profileInfo.twitter} placeholder="Twitter" onChange={handleTwitter}/>
                            <input type="text" className="editInput" defaultValue={profileInfo.instagram} placeholder="Instagram" onChange={handleInsta}/>
                            <input type="text" className="editInput" defaultValue={profileInfo.portfolio} placeholder="Portfolio" onChange={handlePortfolio}/>
                        </div>
                        <div className="locationForm">
                            <p>Billing Information</p>
                            <div className="dropdown">
                                <CountryDropdown
                                    value={country === null ? '' : country}
                                    onChange={(val) => setCountry(val) }
                                />
                            </div>
                            <div className="Statedropdown">
                                <RegionDropdown
                                    blankOptionLabel = "No country selected"
                                    value={state === null ? '' : state}
                                    country={country === null ? '' : country}
                                    onChange={(val) => setState(val) }
                                />
                            </div>
                            <input type='text' className="editInput" defaultValue={profileInfo.address} placeholder="Address" onChange={handleAddress}/>
                        </div>
                    </div>
                    <button disabled={emailError ? true : false} className={loading ? "whiteloading" : ""} onClick={handleSubmit}>Save Changes</button>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Edit