import React, { useEffect,useState } from 'react';
import './resourcePage.css';
import { useParams } from 'react-router-dom';
import Footer from '../../Components/Footer/footer';
import Parser from 'html-react-parser';



const ResourcePage = (props)=>{
    
    const [title, setTitle] = useState('');
    const [structure, setStructure] = useState('')
    const { id } = useParams();
    const resources = [
        {
            id:"1" ,
            title:"How Do I Convert from One Coin to another on MetaMask" ,
            structure:`<div className="exhiDetailHeader"><p className="header">If you already have Ethereum on MetaMask, to convert to USDC follow these few steps;</p>
            <ol>
            <li>Go to browser on MetaMask wallet and search <a href="https://app.uniswap.org/#/swap">https://app.uniswap.org/#/swap</a></li>
            <li>On the exchange, click connect wallet then select Ethereum (ETH) as the currency you&rsquo;re changing from before selecting the stable coin (USDC) you want to change to.</li>
            <li>Click convert and you would be shown the Gas estimate for the transaction.</li>
            <li>Click complete and you should have the equivalent amount of Ethereum in USDC.</li>
            <li>You can then proceed with buying your favorite NFTs on the Creath Marketplace.</li>
            </ol></div>`
        },
        {
            id:"2", 
            title:"Getting Started with MetaMask" ,
            structure:`<div className="exhiDetailHeader"><ol>
                <li>
                <p>Visit&nbsp;<a href="https://metamask.io/download/">https://metamask.io/download/</a>.</p>
                </li>
                <li>
                <p>Choose your application and click the&nbsp;<strong>Install MetaMask</strong>&nbsp;button</p>
                </li>
                <li>
                <p>Choose &ldquo;Create New Account&rdquo; and follow the instructions to set up your password.</p>
                </li>
                <li>
                <p>Next, make sure to back up your 12-word&nbsp;<strong>Secret Recovery Phrase</strong>&nbsp;(also known as a Seed Phrase).&nbsp;You should keep this safe (don&rsquo;t store it digitally) and never reveal it to anyone. It is advisable to write your secret phrase in an important private book and store securely.</p>
                </li>
                <li>
                <p>Now you&rsquo;re ready to connect your wallet! Visit <a href="http://Creath.io">Creath.io</a>, click the sign in button on the top right corner and select MetaMask.</p>
                </li>
                </ol></div>`
        },
        {
            id:"3",
            title:"How to Fund MetaMask with ETH", 
            structure:`<div className="exhiDetailHeader">
                <p className="header"><strong>(1) Buy Directly within MetaMask</strong></p>
                <p className="subHeader">Depending on your region, you may be able to buy ETH directly with a credit or debit card via a third party provider. Please note, buying crypto with a credit card may incur additional fees, depending on which third-party provider you use. You may also not receive the entire crypto amount immediately.</p>
                <ol>
                <li>
                <p>Open the MetaMask wallet extension or the mobile app.</p>
                </li>
                <li>
                <p>Press&nbsp;<strong>Buy ETH</strong>.</p>
                </li>
                <li>
                <p>Select a third-party service such as Wyre, Transak or MoonPay, which will facilitate the transfer of funds from your chosen funding source.</p>
                </li>
                <li>
                <p>Follow the steps provided by the third-party service. You may need to submit personal information like an ID card or proof of residence to meet KYC (Know Your Customer) requirements.</p>
                </li>
                <li>
                <p>Once you&rsquo;re finished purchasing, the funds should appear in your wallet.</p>
                </li>
                </ol>
                <p className="header"><strong>(2) Funding From a Crypto Exchange</strong></p>
                <p className="subHeader">It&rsquo;s also easy to fund your wallet by depositing from a reputable crypto exchange, like&nbsp;<a href="http://ftx.com/">FTX</a>,&nbsp;<a href="http://coinbase.com/">Coinbase</a>&nbsp;or&nbsp;<a href="https://support.opensea.io/hc/en-us/articles/binance.com">Binance</a>, which offers a way to buy crypto with your bank account.</p>
                <p>**Handy tip**: You can use the FTX app to buy ETH and withdraw it to your MetaMask with no withdrawal fees (see example below).</p>
                <ol>
                <li>Open the MetaMask wallet extension or the mobile app.</li>
                <li>Copy your wallet address by clicking the&nbsp;<strong>Account</strong>&nbsp;button with a clipboard icon. If you&rsquo;re using the desktop wallet extension, a&nbsp;<strong>Copy to clipboard</strong>&nbsp;will appear when you hover your mouse over the address.</li>
                <li>Paste your wallet address as a &lsquo;recipient address&rsquo; when withdrawing from your crypto exchange.&nbsp; You can also consult the FAQ for each exchange below. Please note that these are third parties and we cannot make any guarantees about their service.&ndash;&nbsp;<a href="https://help.blockfolio.com/hc/en-us/articles/1260801656210-FTX-App-Trading-Withdrawals">How to send crypto from FTX</a>&ndash;&nbsp;<a href="https://help.coinbase.com/en/coinbase/trading-and-funding/cryptocurrency-trading-pairs/how-to-send-and-receive-cryptocurrency">How to send crypto from Coinbase</a>&nbsp;&ndash;&nbsp;<a href="https://www.binance.com/en/blog/p2p/how-to-send-crypto-to-family-and-friends-worldwide-using-binance-p2p-421499824684902082">How to send crypto from Binance</a>.</li>
                <li>It may take some time for the ETH to appear in your wallet. After processing your withdrawal, your crypto exchange should provide a number called a&nbsp;<strong>Transaction Hash</strong>&nbsp;or&nbsp;<strong>Transaction ID</strong>.&nbsp;This is a unique number for each transaction on the blockchain. You can use this unique number to check the progress of your withdrawal on third-party tools like&nbsp;<strong><a href="https://etherscan.io/">Etherscan</a></strong>. For more information on how to use Etherscan to view transaction information,&nbsp;<a href="https://support.opensea.io/hc/en-us/articles/4406007666579">click here</a>.</li>
                </ol>
                </div>`
        },
        {
            id: "4",
            title: "How can I stay safe and protect my NFTs?",
            structure: `<div className="exhiDetailHeader"><ol>
                <li><strong>Use Official Support Channels;</strong> We recommend only getting help on official channels, and if you do end up asking questions of the broader community, always be cautious. Soliciting help on social channels or Discord, where OpenSea does not provide official customer support, can also make you a target for scammers. You can find answers to frequently asked questions and direct support on the&nbsp;Creath Help Center.</li>
                <li><strong>Never share your secret recovery phrase;</strong> It may sound obvious to all the crypto veterans out there, but you can never hear it enough. Your wallet&rsquo;s secret recovery phrase is private to you and should never be shared, even with those you trust. OpenSea is not a wallet provider and will&nbsp;<strong>never</strong>&nbsp;ask for your wallet&rsquo;s secret recovery phrase. For details on the best way to keep your funds and tokens safe, head to your wallet provider&rsquo;s website and browse the guides and tutorials.</li>
                <li><strong>Make sure your wallet app or extension is the official one;</strong> If you&rsquo;re downloading a wallet browser extension, make sure to get your link directly from the provider&rsquo;s website. When downloading an app, check the reviews and developer info to confirm you&rsquo;re getting the right one, and not an imposter. If you&rsquo;re unsure, there&rsquo;s no harm in reaching out to the provider to clarify.</li>
                <li><strong>Never click on unknown or broken links;</strong> Stay vigilant when browsing websites and interacting with others on social media or Discord. Avoid clicking on ads, images, or links sent by strangers.</li>
                </ol></div>`  
        }
    ]

    useEffect(()=>{
        let Lstructure =  resources[parseInt(id)].structure;
        let Ltitle = resources[parseInt(id)].title;
        console.log(id)
        setTitle(Ltitle);
        setStructure(Lstructure)
    },[props.currentResTitle,props.currentResource])
    return(
        <div className='resPageMain'>
            <div className='resPageHero'>
                <h1>{title}</h1>
            </div>
            <div className='resPageContent'>
                {Parser(structure)}
            </div>
            <div className='projectPageFooter'>
                <Footer/>
            </div>
        </div>
    )
}

export default ResourcePage