import React, { useState } from 'react';
import './faq.css'
import Plus from '../../assets/plus-circle.png'
import Less from '../../assets/Icon.png'
import Parser from 'html-react-parser';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const FAQ = ({question, answer})=>{
    const [show, setShow] = useState(false,{});
    const handleClick = ()=>{
        if (show === false){
            setShow(true)
        }
        else{
            setShow(false)
        }
    }
    return(
        <div className={show ? 'faq_main show' : 'faq_main'} onClick={handleClick} role="button">
            <div className='question'>
                <h3>{question}</h3>
                <LazyLoadImage src={Plus} alt="show more" className='add'/>
                <LazyLoadImage src={Less} alt="show less" className='sub'/>
            </div>
            <div className='answer'>
                {Parser(answer)}
            </div>
        </div>
    )
}

export default FAQ;