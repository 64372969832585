import React, { useEffect, useState } from 'react';
import ArtCard from '../ArtCard/artcard';
import Select from 'react-select';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Footer from '../Footer/footer';
import './marketplace.css'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Loading from './Ellipsis.gif';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Marketplace = ( {Arts, setProduct, setArtist, setCurrentPage, Mediums} )=>{
    const [arts, setArts] = useState('',{});
    const [works, setWorks] = useState(Arts)
    const [currentWorks, setCurrentWorks] =  useState(Arts)
    const [currentPagin, setCurrentPagin] = useState(1)
    const [dropdown, setDropdown] = useState('art',{})
    const [dropdown2, setDropdown2] = useState('',{})
    const [medium, setMedium] = useState("all");
    const [filter, setFilter] = useState(false);
    const [value, setValue] = useState([0, 5000]);
    const [pageNumber, setPageNumber] = useState(0)
    const [topValue, setTopValue] = useState(5000)
    const [bottomValue, setBottomValue] = useState(0)
    const [loading, setLoading] = useState(true)
    const [first, setFirst] = useState(true)
    const [searchValue, setSearchValue] = useState("")
    const options1 = [
        { value: 'art', label: 'Art' },
        { value: 'artist', label: 'Artist' }
    ]

    const options2 = [
        {label: "Recently Added", video: "recently_added"},
        {label: "Price (Highest to Lowest)", value: "price_h_to_l"},
        {label: "Price (Lowest to Highest)", value: "price_l_to_h"}
    ]



    useEffect(()=>{
        let startIndex = (currentPagin * 15) - 15;
        let endIndex = (currentPagin * 15) - 1
        setPageNumber(Math.ceil(works.length/15)); 
        setArts(works.map((user,i)=>{
            if((i >= startIndex) && (i <= endIndex)){
                return(
                    <ArtCard artistName = {works[i].artist_name} physical={works[i].has_physical_copy} Art={works[i]} key={works[i].id} id={works[i].id} img={`${works[i].art_image}`} title={works[i].name} description={works[i].description}  artist={works[i].artist_id} price={works[i].price} style={{color: "red"}} setProduct = {setProduct} setArtist = {setArtist} shouldFetch = {true}/>
                )
            }
        }))
        setLoading(false)
        setFirst(false)
    }, [works,setProduct,setArtist,currentPagin])

    useEffect(()=>{
        sessionStorage.setItem("currentPage", "marketplace")
        setCurrentPage("marketplace")
    })




    useEffect(()=>{
        if(!first){
            if(medium === "all"){
                setWorks(Arts)
                setCurrentWorks(Arts)
            }
            else{
                setWorks((Arts).filter((arr)=>{
                    return arr.category_id === medium;
                }))
                setCurrentWorks((Arts).filter((arr)=>{
                    return arr.category_id === medium;
                }))
            }
        }
    },[medium])

    useEffect(()=>{
        if(!first){
            if(filter === true){
                applyFilter()
            }
            else{
                setFilter(false)
            }
        }
    },[currentWorks,filter,medium])



    function custom_sort(a, b) {
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    }

    const applyFilter = ()=>{
        let workPlaceholder = currentWorks.filter((arr)=>{
            return arr.price >= value[0] && arr.price <= value[1]
        })
        if(dropdown2 === "recently_added"){
            workPlaceholder = workPlaceholder.sort(custom_sort)
        }
        else if(dropdown2 === "price_h_to_l"){
            workPlaceholder = workPlaceholder.sort((a, b)=> {
                return parseFloat(b.price) - parseFloat(a.price);
            });
        }
        else if(dropdown2 === "price_l_to_h"){
            workPlaceholder = workPlaceholder.sort((a, b)=> {
                return parseFloat(a.price) - parseFloat(b.price);
            });
        }
        
        setWorks(workPlaceholder)
        setCurrentPagin(1)
    }

    const searchValueChange = (event)=>{
        setSearchValue(event.target.value.toLowerCase())
        if(event.target.value === '' && dropdown === 'art'){
            setWorks(currentWorks.filter((arr)=>{
                return arr.name.toLowerCase().includes(event.target.value)
            }))
        }
       else if(event.target.value === '' && dropdown === 'artist'){
            setWorks(currentWorks.filter((arr)=>{
                return arr.artist_name.toLowerCase().includes(event.target.value)
            }))
       }
    }

    const searchChange = ()=>{
        if(dropdown === 'art'){
            setWorks(currentWorks.filter((arr)=>{
                return arr.name.toLowerCase().includes(searchValue)
            }))
        }

        if(dropdown === 'artist'){
            setWorks(currentWorks.filter((arr)=>{
                return arr.artist_name.toLowerCase().includes(searchValue)
            }))
        }

    }
    
    const dropdownChange = (option)=>{
        setDropdown(option.value);
    }

    const dropdownChange2 = (option)=>{
        setDropdown2(option.value);
        setFilter(false)
    }

    const rangeChange = (event,newValue)=>{
        setValue(newValue);
        setFilter(false);
    }

    const handleTopChange = (event)=>{
        setTopValue(event.target.value)
        setFilter(false)
        setValue([bottomValue, event.target.value])
    }

    const handleBottomChange = (event)=>{
        setBottomValue(event.target.value)
        setFilter(false)
        setValue([event.target.value, topValue])
    }


    const handleFilterClick = ()=>{
        if(filter === true){
            setFilter(false)
            if(medium === "all"){
                setWorks(Arts)
                setCurrentWorks(Arts)
            }
            else{
                setWorks(Arts.filter((arr)=>{
                    return arr.style.toLowerCase().includes(medium.toLowerCase());
                }))
                setCurrentWorks(Arts.filter((arr)=>{
                    return arr.style.toLowerCase().includes(medium.toLowerCase());
                }))
            }
        }
        else{
            setFilter(true)
        }
    }





    const mediumClick = (n)=>{
        setMedium(n);
        setCurrentPagin(1);
    }


    const paginChange = (event, value)=>{
        setCurrentPagin(value);
    }

    if(loading){
        return(
            <div className='mainLoading'>
                <LazyLoadImage src={Loading} alt="Loading screen"/>
            </div>
        )
    }
    else{
        return(
            <div className='MarketMains'>
                <div className='subMarketMains'>
                    <div className='showWorks'>
                        <div className="showWorksHeader">
                            <h1>Marketplace</h1>
                            <div className='MarketSearch'>
                                <button onClick={searchChange}>Search</button>
                                <input placeholder="Search...." className="MarketSearchInput" onChange={searchValueChange}/>
                                <Select options={options1} 
                                        onChange={dropdownChange} 
                                        className="searchSelect" 
                                        placeholder="Search By"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                              ...theme.colors,
                                              primary25: 'lightgrey',
                                              primary: 'black',
                                            },
                                        })}
                                />
                            </div>
                        </div>
                        <div className="worksButtons">
                            <button className={medium === "all" ? "mediumPicked" : ''} onClick={()=>{mediumClick("all")}}>All</button>
                            {
                                Mediums.map((el,i)=>{
                                    return(
                                        <button key= {Mediums[i].id} className={medium === `${Mediums[i].id}` ? 'mediumPicked' : ''} onClick={()=>{mediumClick(`${Mediums[i].id}`)}}>{Mediums[i].name}</button>
                                    )
                                })
                            }
                        </div>
                        <div className='MarketMain'>
                            <div className="filter filter_down">
                                <div className='filterText'>
                                    <p>Filters</p>
                                </div>
                                <div className='priceSort priceSortDown'>
                                    <div className='priceSortText'>
                                        <p>Price</p>
                                    </div>
                                    <Box sx={{ width: '80%', color: 'black' }}>
                                        <Slider
                                            getAriaLabel={() => 'Temperature range'}
                                            min = {0}
                                            sx={{
                                                color: 'black',
                                            }}
                                            max={5000}
                                            value={value}
                                            step={1}
                                            onChange={rangeChange}
                                            valueLabelDisplay="auto"
                                            size='small'
                                        />
                                    </Box>
                                    <div className='setPrice'>
                                        <input onChange={handleBottomChange} type="number" placeholder="Min" min={0} />
                                        <input onChange={handleTopChange} type="number" placeholder='Max' min={0} />
                                    </div>
                                    
                                </div>
                                <div className='sortFilter'>
                                    <p>Sort By</p>
                                    <Select options={options2} 
                                        onChange={dropdownChange2} 
                                        className="sortBySelect" 
                                        placeholder="Sort By"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                              ...theme.colors,
                                              primary25: 'lightgrey',
                                              primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                                <div className='setFilter'>
                                    <button onClick={handleFilterClick}>{!filter ? "Set Filter" : "Remove Filter"}</button>
                                </div>
                            </div>
                            <div className='arts'>
                                {arts}
                            </div>
                            <div className='pagins'>
                                <Stack spacing={2}>
                                    <Pagination count={pageNumber} variant="outlined" shape="rounded" onChange={paginChange} />
                                </Stack>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='marketFooter'>
                    <Footer/>
                </div>
            </div>
        )
    }
    
}

export default Marketplace;