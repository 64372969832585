import React, { useEffect, useState, useCallback } from 'react';
import Zoom from 'react-medium-image-zoom';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './slidecard.css'
import 'react-lazy-load-image-component/src/effects/blur.css';
import Cancel from '../../assets/cancel.png';
import Modal from 'react-modal';
import Like from '../Like/like';


const SlideCard = (props)=>{
    let subtitle
    const [liked, setLiked] = useState(false)
    const [likeCount, setLikeCount] = useState(0)
    const [newFormat, setNewFormat] = useState("")
    const [modalIsOpen, setIsOpen] = useState(false);
    const user_id = localStorage.getItem('currentUser')
    ? JSON.parse(localStorage.getItem('currentUser')).id
    : null;
    
    const fetchLikeCount = useCallback(async () => {
        try {
          const response = await fetch(`https://creath.tech/api/arts/favorite-count/${props.id}`);
          const data = await response.json();
          setLikeCount(data.data);
        } catch (error) {
          console.error('Error fetching like count:', error);
        }
    }, [props.id]);


    const fetchLikedStatus = useCallback(async () => {
        if(user_id){
            try {
                const response = await fetch(`https://creath.tech/api/arts/favorite-status/${user_id}/${props.id}}`);
                const data = await response.json();
                setLiked(data.hasLiked);
            } catch (error) {
                console.error('Error fetching liked status:', error);
            }
        }
    }, [props.id, user_id]);

    useEffect(() => {
        fetchLikeCount();
        if (user_id) {
          fetchLikedStatus();
        }
    }, [fetchLikeCount, fetchLikedStatus, user_id]);

    useEffect(()=>{
        if(!props.img.includes("mp4")){
            let file = props.img.substr(0, props.img.lastIndexOf(".")) + ".webp";
            let adding = "q_20/"
            let newString = file.slice(0, 29) + adding + file.slice(29);
            setNewFormat(newString);
        }else{
            let addings = "q_20/"
            let newStrings = props.img.slice(0, 29) + addings + props.img.slice(29);
            setNewFormat(newStrings);
        }
    })

    useEffect(()=>{
        const favorites = JSON.parse(sessionStorage.getItem('favorites'))
        if(favorites){
            for(let i =0; i < favorites.length; i++){
                if (props.id === favorites[i].id){
                    setLiked(true);
                }
            }
        }
    })

    const navigate = useNavigate();

    Modal.setAppElement('#root');

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const setModalStatus = ()=>{
        setIsOpen(true);
    }


    const ArtProfile = {
        title : `${props.title}`,
        description : `${props.description}`,
        price : `${props.price}`,
        artist : `${props.artist}`,
        id : `${props.id}`,
        image : `${props.img}`,
        style: `${props.style}`,
        physical:  `${props.physical}`
    }

    const handleLike = useCallback(async () => {
        if(localStorage.getItem('isLoggedIn') === "true" && user_id){
          
            try {
                let data = new FormData()
                data.append('art_id', `${props.id}`)
                data.append('user_id', `${user_id}`)

                const response = liked ? await fetch(`https://creath.tech/api/arts?art_id=${props.id}&user_id=${user_id}`, {
                    method: 'DELETE',
                  }) : await fetch('https://creath.tech/api/favorites',{
                    method: 'POST',
                    body: data
                })

                const status = await response.json();
                if (response.ok) {
                    fetchLikedStatus();
                    fetchLikeCount();
                } else {
                    console.error('Error updating like:', status.error);
                }
            } catch (error) {
                console.error('Error updating like:', error);
            }
        }
        else{
            setModalStatus()
        }
    },[liked, fetchLikeCount, fetchLikedStatus, props.id, user_id])

    const showProduct =  ()=>{
        localStorage.setItem('Art', JSON.stringify(props.Art))
        props.setProduct(ArtProfile)
        navigate(`/product/${props.id}`)
    }

    const showArtist =  ()=>{
        navigate(`/artistProfile?id=${props.artist}`)
    }

    return(
        <div className="keen-slider__slide slide">
            {props.img.includes('mp4') ?<video loop ={true} autoPlay={true} muted={true} playsInline controls={false} className="home_media" ><source src={props.img} type="video/mp4"/></video> : <Zoom><LazyLoadImage className="home_media" src={newFormat} effect='blur' alt={props.title} /></Zoom> }
            <div className={ props.img.includes('mp4') ? "Text down" : "Text"}>
                <p className="art_name" onClick={showProduct}>{props.title}</p>
                <p className="artist_name" onClick={showArtist}>{props.artistName}</p>
                <hr />
            </div>
            <div className={props.img.includes('mp4') ? "priceLike up" : "priceLike"}>
                <p className='artPrice'>{props.Art.collection_id === "7" ? "Not for Sale" : props.price + " USDC"}</p>
                <div>
                    <Like like={liked} onClick = {handleLike}/>
                    <p>{likeCount}</p>
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >   
                <div className='exhiModal'>
                    <h2 className='exhiModalHeader' ref={(_subtitle) => (subtitle = _subtitle)}>Note</h2>
                    <p>You need to sign in or sign up to like an artwork</p>

                </div>
                <div className='closeExhiModal' onClick={closeModal} role='button'>
                    <LazyLoadImage src={Cancel} alt="Close Modal"/>
                </div>
            </Modal>
        </div>
    )
}

export default SlideCard;
