import React from 'react'
import { useNavigate } from "react-router-dom";


const Error = ()=>{
    const navigate = useNavigate()

    return(
        <div className='four'>
            <h1>404</h1>
            <p>Page not found, you can reload or go back to home</p>
            <button onClick={()=>{navigate('/')}}>Go to home</button>
        </div>
    )
}

export default Error