import React, {useState} from "react";
import Footer from "../../Components/Footer/footer";
import { useNavigate } from 'react-router-dom';
import './creator.css';



const Creator = ()=>{

    const [loading,setLoading] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [portfolio, setPortfolio] = useState("")
    const [bio, setBio] = useState("")
    const [submitStatus, setSubmitStatus] = useState(5)
    const navigate = useNavigate()


    const data = new FormData
    data.append("full_name", name)
    data.append("email", email)
    data.append("project", portfolio)
    data.append("experience", bio)

    const onChangeHandler = (event) => {
        if(event.target.name === "name"){
            setName(event.target.value)
        }
        else if(event.target.name === "email"){
            setEmail(event.target.value)
        }
        else if(event.target.name === "portfolio"){
            setPortfolio(event.target.value)
        }
        else{
            setBio(event.target.value)
        }
    }

    const submitForm = (e)=>{
        setLoading(true)
        e.preventDefault();
        fetch("https://creath.tech/api/joins", {
            method: 'POST',
            body: data
        }).then((response)=>{
            return response.json()
        })
        .then((data)=>{
            if(data.message && data.message === "Join request sent Successfully!"){
                setSubmitStatus(1)
                setLoading(false)
                setTimeout(()=>{
                    navigate("/")
                },5000)
            }
            else{
                setSubmitStatus(0)
                setLoading(false)
            }
        })
        .catch((e)=>{
            setSubmitStatus(0)
        })
    }




    return(
        <div className="creatorMain">
            <h1 className="creatorHead">Become A Creator</h1>
            <p className="creatorHeadDesc">Our Marketplace contains works from some of the best artists from around the globe, do you think you have what it takes to be here, fill the form and we will get back to you if your profile fits what we are looking for.</p>
            <div className="creatorForm">
                <form onSubmit={submitForm}>
                    <label htmlFor="name">Name</label>
                    <input name="name" type="text" placeholder="Enter your name" required onChange={onChangeHandler}/>

                    <label htmlFor="email">Email</label>
                    <input name="email" type="email" placeholder="Enter your email" required onChange={onChangeHandler}/>

                    <label htmlFor="portfolio">Link to your portfolio</label>
                    <input name="portfolio" type="text" required placeholder="Link to your portfolio" onChange={onChangeHandler}/>

                    <label htmlFor="bio">Tell us about yourself</label>
                    <textarea name="bio" type="text" placeholder="Tell us about yourself" required rows="7" cols="50" maxLength="500"  onChange={onChangeHandler}/>

                    <input className={loading ? "whiteloading" : ""} type="submit" value="Submit" onChange={onChangeHandler}/>
                </form>
            </div>
            <p className={submitStatus === 1 ? "showGreen" : submitStatus === 0 ? "showRed" : "showDont"}>{submitStatus === 1 ? "Form Submitted Successfully, we will get back to you soon." : submitStatus === 0 ? "There was an error please try again" : ""}</p>
            <div className="creatorBottom">
                <h1>Become A Creator</h1>
                <p>Creath Art Marketplace operates as a hybrid gallery model utilizing the Ethereum Blockchain. Our platform goes beyond showcasing artworks solely online, extending to physical exhibitions held in diverse locations worldwide. Our primary goal is to offer artists a robust platform to promote and sell their artwork, enabling them to advance their careers. As part of our services, Creath retains a 20% commission on all primary sales facilitated through our platform.</p>
                <p>The Creath Art Marketplace invites artists from around the world working in diverse mediums such as Painting, Sculpture, Photography, Digital Art (2D &amp; 3D animation), and video art to submit their work. We are open to showcasing a wide range of artistic expressions and encourage artists from different backgrounds to participate in our platform.</p>
                <p>In the agreement between Artists and Creath Art Marketplace, artists will be required to exclusively feature the selected artworks on our platform. These artworks cannot be privately sold or exhibited through other galleries for the duration specified in the agreement. Artists are responsible for carefully packaging the artworks to ensure safe shipment and handing them over to the courier. However, Creath Art Marketplace will coordinate the shipping process, and the collector will be responsible for covering the shipping fees. Artists will only need to cover the expenses associated with packaging materials. These are just a few key points of the gallery agreement, which will be thoroughly discussed during the initial online meeting between the parties involved.</p>
                <p>If you are interested in partnering with&nbsp;Creath Art Marketplace for an exhibition, please send your portfolio in PDF format to: <strong><a href="mailto:Artists@creath.io">Artists@creath.io</a></strong></p>
                <p>This document should include images of your artworks listed with the following information provided and questions answered:</p>
                <ul>
                <li><strong>Full Name</strong></li>
                <li><strong>Artist Name</strong></li>
                <li><strong>Email</strong></li>
                <li><strong>Biography</strong></li>
                <li><strong>Please provide links to your portfolio and social media ( showcasing your work</strong></li>
                <li><strong>Which medium(s) are you best known for?</strong></li>
                <li><strong>What would be your primary goal when working with Creath?</strong></li>
                <li><strong>Have you released your Art on other platforms? If yes tell Us about it?</strong></li>
                </ul>
                <p>After submitting your artwork, please allow a few days for the Creath team to review your submission. If your work meets our criteria and standards, we will contact you to schedule a personal meeting. This meeting serves as an opportunity for both parties to get to know each other better and discuss the details of the marketplace agreement. Following this meeting, both the artist and Creath Art Marketplace will have sufficient time to reflect and make a decision on whether the collaboration is the right fit for them.</p>
                <p>Thanks for your interest in working with Creath!</p>
                <p>We look forward to working with you!</p>
            </div>
            <Footer/>
        </div>
    )
}

export default Creator