import './App.css';
import Home from './Containers/Home/home'
import { ethers } from 'ethers';
import {Route, Routes} from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop/scroll';
import Marketplace from './Components/Marketplace/markeplace';
import Product from './Containers/ProductPage/product'
// import Artist from './Containers/ArtistProfile/artistProfile';
import CollectionProfile from './Containers/CollectionProfile/collectionProfile';
import { useEffect, useState } from 'react';
import Exhibitions from './Containers/Exhibition/exhibition';
import Login from './Containers/Login/login';
import Terms from './Containers/Terms/terms';
import Resources from './Containers/Resources/resource';
import ResourcePage from './Containers/ResourcePage/resourcePage';
import ExhibitionProfile from './Containers/ExhibitionProfile/exhibitionProfile';
import Artists from './Containers/ArtistPage/artists';
import Profile from './Components/Profile/profile';
import FaqPage from './Containers/FaqPage/faqPage';
import LiveChat from './Components/LiveChat/livechat';
import Checkout from './Containers/Checkout/checkout';
import ArtistProfile from './Containers/ArtistProfile/artistProfile';
import AccountVerification from './Containers/AccountVerification/accountVerification';
// import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import Edit from './Containers/EditProfile/editProfile';
import Web3 from 'web3';
// import { useWeb3React } from '@web3-react/core';
import Loading from './Ellipsis.gif'
import Header from './Components/Header/header';
import MobileHeader from './Components/MobileHeader/mobileNav';
// import walletConnect from '@web3-onboard/walletconnect';
import Creator from './Containers/BecomeACreator/creator';
import Shipping from './Containers/ShippingPage/shipping';
// import { useWeb3Modal } from '@web3modal/react';
import { useNavigate } from 'react-router-dom';
// import { useNetwork, useSwitchNetwork, useAccount, useDisconnect } from 'wagmi';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BlogPage from './Containers/BlogPage/blogpage';
import BlogsPage from './Containers/Blogs/blogs';
import Error from './Containers/Error/error';



function App() {

  const [product, setProduct] = useState('',{});
  const [exhibitions, setExhibitions] = useState([])
  const [arts, setArts] = useState([])
  const [sliderArts, setSliderArts] = useState([])
  const [collections, setCollections] = useState([])
  const [artist, setArtist] = useState('',{});
  const [artists, setArtists] = useState([]);
  const [medium, setMedium] = useState([]);
  const [blog, setBlog] = useState([]);
  const [currentAccount, setCurrentAccount] = useState(null)
  const [currentPage, setCurrentPage] = useState("home")
  const [currentResource, setCurrentResource] = useState(null)
  const [currentResTitle, setCurrentResTitle] = useState('')
  const [isLoggedIn, setIsLoggedIn]  = useState(false)
  const [Library, setLibrary] = useState({})
  const [connectionState, setConnectionState] = useState(0)
  const [disconnectState, setDisconnectState] = useState(0)
  const [wallet, setWallet] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [userType, setUserType] = useState("guest")
  const web3 = new Web3(window.ethereum);
  const navigate = useNavigate()
  // const { chains, switchNetwork } = useSwitchNetwork({
  //     onError(error) {
  //     }
  // })

  useEffect(()=>{
    const fetchData = async () => {
      try {
        const [artsResponse, artistsResponse, collectionsResponse, exhibitionsResponse, mediumResponse, blogResponse] = await Promise.all([
          fetch('https://creath.tech/api/arts'),
          fetch('https://creath.tech/api/artists'),
          fetch('https://creath.tech/api/collections'),
          fetch('https://creath.tech/api/exhibitions'),
          fetch("https://creath.tech/api/categories"),
          fetch("https://creath.tech/api/blogs")
        ]);

        const [artsData, artistsData, collectionsData, exhibitionsData, mediumData, blogData] = await Promise.all([
          artsResponse.json(),
          artistsResponse.json(),
          collectionsResponse.json(),
          exhibitionsResponse.json(),
          mediumResponse.json(),
          blogResponse.json(),
        ]);

        var test = [];
        var some = artsData.data.sort(() => Math.random() - 0.5)
        for(let i = 0; i < 10; i ++){
          test.push(some[i])
        }

        setArts(some);
        setSliderArts(test);
        setArtists(artistsData.data);
        setCollections(collectionsData.data);
        setExhibitions(exhibitionsData.data);
        setMedium(mediumData.data);
        setBlog(blogData.data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // try{
    //     fetch("https://creath.tech/api/arts")
    //     .then(response => response.json())
    //     .then((data)=>{
    //       var test = [];
    //       var some = data.data.sort(() => Math.random() - 0.5)
    //       for(let i = 0; i < 10; i ++){
    //         test.push(some[i])
    //       }
    //       setArts(data.data);
    //       test.sort(() => Math.random() - 0.5);
    //       sessionStorage.setItem('small', JSON.stringify(test))
    //       sessionStorage.setItem('arts',JSON.stringify(some))
    //     })
    //     .catch((e)=>{
          
    //     })
      


    //   fetch("https://creath.tech/api/artists")
    //   .then(response => response.json())
    //   .then((data)=>{
    //     let sort_artists = data.data.sort(function(a, b) {
    //       return compareStrings(a.username, b.username);
    //     })
    //     sessionStorage.setItem('artists',JSON.stringify(sort_artists))
    //     setArtists(sort_artists)
    //   })
    //   .catch((e)=>{
       
    //   })
    
    
  
    //   fetch("https://creath.tech/api/collections")
    //   .then(response => response.json())
    //   .then((data)=>{
    //     setCollections(data.data)
    //     sessionStorage.setItem('collections',JSON.stringify(data.data))
    //   })
    //   .catch((e)=>{
        
    //   })  
    

    //   fetch("https://creath.tech/api/exhibitions")
    //   .then(response => response.json())
    //   .then((data)=>{
    //     setExhibitions(data.data)
    //     sessionStorage.setItem('exhibitions',JSON.stringify(data.data))
    //   })
    //   .catch((e)=>{
        
    //   })
    

    //   fetch("https://creath.tech/api/categories")
    //     .then(response => response.json())
    //     .then((data)=>{
    //       sessionStorage.setItem("medium", JSON.stringify(data.data))
    //     }
    //   ).catch((e)=>{
        
    //   })
    

    //   fetch("https://creath.tech/api/blogs")
    //   .then(response =>  response.json())
    //   .then((data)=>{
    //     sessionStorage.setItem("blog", JSON.stringify(data.data))
    //   })
    //   .catch((e)=>{

    //   })
    // }
    // catch{

    // }
    
  },[])


  useEffect(()=>{
    let logged = localStorage.getItem('isLoggedIn')
    if(logged === "true"){
      let type = localStorage.getItem('connector');
      if(type === "injected"){
        connectInjectedWallet()
      }
    }
  },[])




  // const { open, close} = useWeb3Modal();
  // const {isConnected} = useAccount()
  // const account = useAccount({
  //   onConnect({ address, connector, isReconnected }) {
  //     if(isReconnected){
  //       localStorage.setItem('isLoggedIn', 'true');
  //       localStorage.setItem('connector','walletConnect')
  //       localStorage.setItem('account', account.address)
  //       setCurrentAccount(account.address)
  //       switchNetwork(chains[1].id)
  //     }
  //     else{
  //       localStorage.setItem('isLoggedIn', 'true');
  //       localStorage.setItem('connector','walletConnect')
  //       localStorage.setItem('account', account.address)
  //       setCurrentAccount(account.address)
  //       sessionStorage.setItem("verification", "true")
  //       navigate('/loginVerification')
  //       switchWalletConnectNetwork(1)
  //     }
  //   },
  //   onDisconnect() {
  //     localStorage.removeItem('account');
  //     setCurrentAccount(null)
  //     localStorage.setItem('isLoggedIn', 'false')
  //     setIsLoggedIn(false)
  //   },
  // })

  function compareStrings(a, b) {
    // Assuming you want case-insensitive comparison
    a = a.toLowerCase();
    b = b.toLowerCase();
  
    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }
  
  // const { disconnect } = useDisconnect()

  const switchMetaMaskNetwork = async (n)=>{
      try{
        await web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{chainId: web3.utils.toHex(`${n}`)}]
        })
      }
      catch(e){  
  
      }
  }

  const switchWalletConnectNetwork = (n)=>{
    // try{
    //   switchNetwork(chains[n].id);
    // }
    // catch{

    // }
  }

  const addNetwork = async ()=>{
    try{
      await web3.currentProvider.request({
      method: 'wallet_addEthereumChain',
      params: [{
      chainId: '0xa',
      chainName: 'OP Mainnet',
      nativeCurrency: {
          name: 'Optimism ETH',
          symbol: 'ETH',
          decimals: 18
      },
      rpcUrls: ['https://mainnet.optimism.io']
      }]
      })
      .then(()=>{
        switchMetaMaskNetwork(10)
      })
      .catch((error) => {

      })


    }
    catch(e){
      
    }
  }

  async function connectInjectedWallet() {
    if(localStorage.getItem('isLoggedIn') === 'true'){
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();
        localStorage.setItem('account', accounts[0])
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner(accounts[0]);
        setWallet(signer)
        setLibrary(provider)
        setConnectionState(connectionState + 1)
        setIsLoggedIn(true);
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('connector','injected')
        const currentChain  = await web3.eth.getChainId()
        if(currentChain !== 10){
          addNetwork()
        }
      } catch (error) {
      }  
    }
    else{
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();
        localStorage.setItem('account', accounts[0])
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner(accounts[0]);
        setWallet(signer)
        setLibrary(provider)
        setConnectionState(connectionState + 1)
        setIsLoggedIn(true);
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('connector','injected')
        const currentChain  = await web3.eth.getChainId()
        if(currentChain !== 10){
          addNetwork()
        }
        sessionStorage.setItem("verification", "true")
        navigate('/loginVerification')
      } catch (error) {
      }
    }
  }

  if(localStorage.getItem('connector') === "injected"){
    window.ethereum.on('accountsChanged', (accounts)=>{
      if(accounts.length !== 0){
        localStorage.setItem('account', accounts[0])
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner(accounts[0]);
        setWallet(signer)
        setLibrary(provider)
        setConnectionState(connectionState + 1)
        setIsLoggedIn(true);
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('connector','injected')
        sessionStorage.setItem("verification", "true")
        navigate('/loginVerification')
      }
      else{
        web3.setProvider(null);
        localStorage.removeItem('account');
        localStorage.setItem('connector','')
        sessionStorage.removeItem("favorites");
        setCurrentAccount(null)
        localStorage.setItem('isLoggedIn', 'false')
        setIsLoggedIn(false)
        setDisconnectState(disconnectState + 1)
      }
    })
  }


  async function connectedWalletConnect(){
    // open()
  }


  async function disconnectWallet(){
    // disconnect()
  }


  if(arts.length !== 0){
    return (
      <div className='App'>
            <Header disconnectState = {disconnectState} setDisconnectState = {setDisconnectState} connectionState = {connectionState} setProduct = {setProduct} setArtist = {setArtist} currentAccount = {currentAccount} setCurrentAccount = {setCurrentAccount} currentPage = {currentPage} setCurrentPage = {setCurrentPage} setIsLoggedIn = {setIsLoggedIn} disconnectWallet={disconnectWallet} addNetwork={addNetwork} switchMetaMaskNetwork={switchMetaMaskNetwork} switchWalletConnectNetwork={switchWalletConnectNetwork}/>
            <MobileHeader disconnectState = {disconnectState} setDisconnectState = {setDisconnectState} connectionState = {connectionState} setProduct = {setProduct} setArtist = {setArtist} currentAccount = {currentAccount} setCurrentAccount = {setCurrentAccount} currentPage = {currentPage} setCurrentPage = {setCurrentPage} setIsLoggedIn = {setIsLoggedIn} disconnectWallet={disconnectWallet} addNetwork={addNetwork} switchMetaMaskNetwork={switchMetaMaskNetwork} switchWalletConnectNetwork={switchWalletConnectNetwork}/>
            <ScrollToTop/>
           <Routes>
             <Route path='/' element={<Home blogs = {blog} arts = {sliderArts} artists = {artists} setProduct = {setProduct} setArtist = {setArtist} setCurrentPage = {setCurrentPage} />}/>
             <Route path='/marketplace' element={<Marketplace Arts = {arts} setProduct = {setProduct} setArtist = {setArtist}  setCurrentPage = {setCurrentPage} Mediums = {medium}/>}/>
             <Route path='/product/:id' element={<Product Collections = {collections} Artss = {arts} Artists = {artists} Medium = {medium} setProduct = {setProduct} setArtist = {setArtist} wallet = {wallet} library = {Library} product = {product} currentPage = {currentPage} setCurrentPage = {setCurrentPage} />}/>
             <Route path='/exhibition' element={<Exhibitions exhibitions = {exhibitions} setCurrentPage = {setCurrentPage}/>}/>
             <Route path='/login' element={<Login  currentAccount = {currentAccount} setCurrentAccount = {setCurrentAccount} currentPage = {currentPage} setCurrentPage = {setCurrentPage} setIsLoggedIn = {setIsLoggedIn} connectedWalletConnect={connectedWalletConnect}  connectInjectedWallet = {connectInjectedWallet} disconnectWallet={disconnectWallet} />}/>
             <Route path='/loginVerification' element={<AccountVerification setCurrentUser = {setCurrentUser} setUserType = {setUserType}  currentAccount = {currentAccount} setCurrentAccount = {setCurrentAccount} currentPage = {currentPage} setCurrentPage = {setCurrentPage} setIsLoggedIn = {setIsLoggedIn} connectedWalletConnect={connectedWalletConnect}  connectInjectedWallet = {connectInjectedWallet} disconnectWallet={disconnectWallet} />}/>
             <Route path='/termsandcondition' element={<Terms  currentAccount = {currentAccount} setCurrentAccount = {setCurrentAccount} currentPage = {currentPage} setCurrentPage = {setCurrentPage} setIsLoggedIn = {setIsLoggedIn} disconnectWallet={disconnectWallet} />} />
             <Route path='/resources' element={<Resources setCurrentResTitle = {setCurrentResTitle}  currentAccount = {currentAccount} setCurrentAccount = {setCurrentAccount} currentPage = {currentPage} setCurrentPage = {setCurrentPage} setCurrentResource = {setCurrentResource} setIsLoggedIn = {setIsLoggedIn} disconnectWallet={disconnectWallet}/>} />
             <Route path='/resource/:name/:id' element={<ResourcePage setCurrentResource = {setCurrentResource}  setCurrentResTitle = {setCurrentResTitle} currentResTitle = {currentResTitle} currentResource={currentResource} currentAccount = {currentAccount} setCurrentAccount = {setCurrentAccount} currentPage = {currentPage} setCurrentPage = {setCurrentPage} setIsLoggedIn = {setIsLoggedIn} disconnectWallet={disconnectWallet}/>}/>
             <Route path='/blog/:id' element={<BlogPage />} />
             <Route path='/blogs' element={<BlogsPage blog={blog}/>}/>
             <Route path='/exhibitionPage/:name' element={<ExhibitionProfile setProduct = {setProduct} Arts = {arts}  setArtist = {setArtist} collections = {collections} exhibitions = {exhibitions}  currentPage = {currentPage} setCurrentPage = {setCurrentPage}  />}/>
             <Route path='/profile' element={<Profile setProduct = {setProduct} setArtist = {setArtist}  wallet = {wallet} library = {Library} currentAccount = {currentAccount} setCurrentAccount = {setCurrentAccount} currentPage = {currentPage} setCurrentPage = {setCurrentPage} userType={userType} setIsLoggedIn = {setIsLoggedIn} disconnectWallet={disconnectWallet} />}/>
             <Route path='/faqs' element={<FaqPage currentAccount = {currentAccount} setCurrentAccount = {setCurrentAccount} currentPage = {currentPage} setCurrentPage = {setCurrentPage} setIsLoggedIn = {setIsLoggedIn} disconnectWallet={disconnectWallet} />}/>
             <Route path='/checkout/:id' element={<Checkout Artss = {arts} Artists = {artists} Collections = {collections} addNetwork = {addNetwork} wallet = {wallet} library = {Library}  setCurrentPage = {setCurrentPage} isLoggedIn = {isLoggedIn} setIsLoggedIn = {setIsLoggedIn}/>}/>
             <Route path='/artistProfile'  element={<ArtistProfile Artss = {arts} Artists = {artists} Collections = {collections}  artist = {artist} arts = {arts} wallet = {wallet} library = {Library} currentPage = {currentPage} setCurrentPage = {setCurrentPage} userType={userType} />}/>
             <Route  path='/collectionProfile' element={<CollectionProfile arts = {arts}  currentAccount = {currentAccount} setCurrentAccount = {setCurrentAccount} currentPage = {currentPage} setCurrentPage = {setCurrentPage} userType={userType} setIsLoggedIn = {setIsLoggedIn} disconnectWallet={disconnectWallet} setProduct = {setProduct} setArtist = {setArtist}/>} />
             <Route path='/editProfile'  element={<Edit arts = {arts}  currentAccount = {currentAccount} setCurrentAccount = {setCurrentAccount} currentPage = {currentPage} setCurrentPage = {setCurrentPage} userType={userType} setIsLoggedIn = {setIsLoggedIn} disconnectWallet={disconnectWallet} setProduct = {setProduct} setArtist = {setArtist} />}/>
             <Route path='/artists' element={<Artists  setArtist = {setArtist}  setCurrentPage = {setCurrentPage} artists = {artists} />}/>
             <Route path="/creatorForm" element={<Creator/>}/>
             <Route path="/shipping" element={<Shipping/>}/>
             <Route path='*' element={<Error/>} />
           </Routes>
         <LiveChat/>
      </div>
     );
  }
  else{
    return(
      <div className='mainLoading'>
        <LazyLoadImage src={Loading} alt="Loading screen"/>
      </div>
    )
  }
}

export default App;
